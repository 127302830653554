export default {
  KubeStar: "KubeStar",
  KubeStarTerminalTools: "KubeStar Terminal Tools",
  history: "History",
  historyEmpty: "No recent records",
  mainNavTitle: "KubeStar Services",
  knowKubeStar: "About",
  Dashboard: "Dashboard",
  Event: "Events",
  aboutUs:
    "KubeStar An enterprise-level distributed container platform for cloud-native applications and cross-cloud infrastructure management, KubeStar can manage your cloud-native applications and infrastructure across environments, clusters, regions and clouds.",

  KubeStarControlPanel: "KubeStar Control Plane",
  KubeStarDocs: "KubeStar Docs",

  userName: "Username",
  email: "Email",
  isAdmin: "Administrator",
  password: "Password",
  confirmPassword: "Confirm password",
  personalSetting: "Profile",
  changePassword: "Change password",

  vendor: "Vendor",
  region: "Region",
  path: "Path",
  port: "Port",
  hostPort: "hostPort",
  protocol: "Protocol",
  createTime: "Create timestamp",
  tag: "Labels",
  detail: "Details",
  annotations: "Annotations",
  template: "template",
  resourceRelated: "Related resources",
  host: "Domain name",
  ingressRules: "Ingress Rules",
  ingressAddress: "Address",
  ingressHost: "Host",

  location: "Location",
  image: "Image",
  cpu: "CPU",
  memory: "Memory",
  name: "Name",
  version: "Version",
  currentVersion: "Current version",
  basicConf: "Basic information",
  reason: "Reason",
  message: "Message",
  time: "Time",
  status: "Status",
  role: "Role",
  type: "Type",
  address: "Address",
  endpoints: "Endpoints",

  effect: "Access",
  effectAllow: "Enable",
  effectDeny: "Disable",

  condition: "Status",
  description: "Description",

  Audit: "Audit",
  zone: "zone",

  arch: "architecture"
};
