<template>
  <span>
    <span @click="edit('general')">
      {{ $t("personalSetting") }}
    </span>

    <el-dialog
      :title="$t('personalSetting')"
      :visible.sync="dialogVisible"
      top="30px"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="800px"
    >
      <div v-loading="infoLoading">
        <el-tabs type="card" v-model="activeName" v-if="dialogVisible && !infoLoading">
          <el-tab-pane :label="$t('basicConf')" name="general">
            <user-form :form="form" ref="generalForm" general />
          </el-tab-pane>

          <el-tab-pane :label="$t('changePassword')" name="password">
            <user-form :form="form" ref="passwordForm" password />
          </el-tab-pane>

          <el-tab-pane label="MFA设置" name="mfa" v-if="needMFA">
            <div class="alerts-container">
              <el-alert
                title="系统开启了MFA验证，请绑定MFA"
                show-icon
                type="warning"
                effect="dark"
                v-if="needMFA && !form.mfaEnableFlag"
              >
              </el-alert>

              <el-alert
                title="您已绑定了MFA，如需解除绑定，请点击 解除绑定MFA"
                show-icon
                type="success"
                effect="dark"
                v-if="needMFA && form.mfaEnableFlag"
              >
              </el-alert>
            </div>

            <div class="title" style="font-size: 16px; margin-bottom: 10px;">
              什么是 MFA？
            </div>
            <div class="desc">
              Multi-Factor Authentication (MFA)
              是一种简单有效的最佳安全实践方法，它能够在用户名和密码之外再额外增加一层安全保护。 启用 MFA
              后，用户登录KubeStar时，系统将要求输入用户名和密码（第一安全要素），然后要求输入来自其 MFA
              设备的动态验证码（第二安全要素），双因素的安全认证将为您的账户提供更高的安全保护
            </div>

            <div class="mfa-container">
              <div class="bind-mfa" v-if="!form.mfaEnableFlag">
                <div>
                  <el-button type="primary" plain size="small" @click="bindMFA" :loading="mfaLoading">
                    绑定MFA
                  </el-button>
                  <div class="help">
                    点击绑定MFA后，系统会生成一张二维码，请使用设备扫描二维码进行绑定
                  </div>
                </div>

                <div class="mfa-image-container" v-loading="mfaLoading">
                  <img :src="'data:image/png;base64,' + base64" v-if="base64" />
                </div>
              </div>

              <div v-else>
                <el-button type="danger" plain size="small" @click="deleteMFA" :loading="mfaLoading">
                  解除绑定MFA
                </el-button>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <span slot="footer" v-if="activeName != 'mfa'">
        <el-button type="primary" @click="submit" size="small" :loading="loading">
          {{ activeName == "secretKey" ? "生成AK/SK" : $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </span>
</template>

<script>
import {userUpdate, getUserInfo, bindMFA, deleteMFA, resetPassword} from "api/user";
import UserForm from "./Form.vue";

export default {
  components: { UserForm },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      infoLoading: true,
      mfaLoading: false,
      activeName: "",
      form: {}
    };
  },

  methods: {
    async edit(activeName) {
      this.dialogVisible = true;
      this.activeName = activeName;

      this.infoLoading = true;
      let response = await getUserInfo(this.userId);
      this.infoLoading = false;

      if (response.code === 0) {
        this.form = response.data;
      }
    },

    bindMFA() {
      this.mfaLoading = true;
      bindMFA(this.form).then(response => {
        this.mfaLoading = false;
        if (response.code === 0) {
          this.base64 = response.data;
        }
      });
    },

    deleteMFA() {
      this.mfaLoading = true;
      deleteMFA(this.form.userName).then(response => {
        this.mfaLoading = false;
        if (response.code === 0) {
          this.dialogVisible = false;
        }
      });
    },

    submit() {
      if (this.activeName === "general") {
        this.$refs["generalForm"].$refs["form"].validate(valid => {
          if (valid) {
            this.$set(this.form, "updateType", ["general"]);
            this.submitFn();
          }
        });
      } else if (this.activeName === "password") {
        this.$refs["passwordForm"].$refs["form"].validate(valid => {
          if (valid) {
            this.$set(this.form, "updateType", ["password"]);
            this.submitChangePassword();
          }
        });
      } else if (this.activeName === "secretKey") {
        this.$set(this.form, "updateType", ["secretKey"]);
        this.submitFn();
      }
    },

    submitChangePassword() {
      this.loading = true;
      let passwordChangeForm = {
        username: this.form.name,
        password: this.form.password
      };
      resetPassword(passwordChangeForm).then(response => {
        this.loading = false;
        if (response.code === 0) {
          this.activeName != "secretKey" ? (this.dialogVisible = false) : this.edit("secretKey");
        }
      });
    },

    submitFn() {
      this.loading = true;
      userUpdate(this.form).then(response => {
        this.loading = false;
        if (response.code === 0) {
          this.activeName != "secretKey" ? (this.dialogVisible = false) : this.edit("secretKey");
        }
      });
    }
  },

  computed: {
    needMFA() {
      return this.$store.state.app.configs.MFA;
    },

    userInfo() {
      return this.$store.state.app.userInfo;
    },

    userId() {
      return this.$store.state.app.userId;
    }
  },

  async mounted() {
    if (!this.userInfo.mfaEnableFlag && this.needMFA) {
      await this.edit("mfa");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.password-container {
  span {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

.alerts-container {
  margin-bottom: 20px;
}

.mfa-container {
  & > .bind-mfa {
    @include flex();

    .mfa-image-container {
      width: 100px;
      height: 100px;

      & > img {
        display: block;
        width: 100%;
      }
    }
  }

  margin-top: 10px;
}
</style>
