export default {
  auditList: "集群拓扑图",
  auditSearchList: "Filter condition",
  auditReqTime: "Request timestamp",
  auditCluster: "Cluster",
  auditNamespace: "Namespace",
  auditResource: "Resource",
  auditResourceName: "Resource name",
  auditVerb: "Verb",
  auditSubVerb: "Sub verb",
  auditUser: "User",
  auditReqURI: "Request URI",
  auditPlatform: "Platform",
  auditReqObject: "Request Object",
  auditRspObject: "Response Object",
  auditSourceIPs: "Source IP"
};
