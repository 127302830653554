export default {
  inputDownloadFileName: "Please input the filename to download",
  download: "Download",
  tip: "Tip",
  connectionLost: " 🔗 Connection has been disconnected, do you want to reconnect?",
  reconnect: "Reconnect to Pod",
  addExecCommand: "Add an execution command",
  addContainerRunCommand: "Add run command",
  addContainerRunCommandParam: "Add run command params"
};
