export default {
    Gateway: "Gateway",
    GatewayClass: "GatewayClass",
    HTTPRoute: "HTTP Route",
    gatewayName: "Gateway name",
    gatewayClassName: "Gateway class",
    gatewayAddress: "Gateway address",
    gatewayStatus: "Gateway status",

    gatewayBasicInformation: "网关基本信息",

    gatewayClassController: "Controller",
    gatewayClassStatus: "Status",
    gatewayClassDesc: "Description",

    httpRouteHostNames: "HostName",

    httpRouteMatch: "HTTP Route Matches",
    routeMatch: "Route Matches",
    pathMatch: {
        Exact: "正確に一致",
        PathPrefix: "パスプレフィックス一致",
        RegularExpression: "正規表現一致"
    }
};
