export default {
  Service: "サービス",
  Application: "アプリケーション",
  serviceName: "サービス名称",
  serviceConf: "サービス設定",
  serviceType: "サービス種類",
  clusterIP: "内部使用(クラスタIP設定)",
  LoadBalancer: "外部公開(LBの設定)",
  targetPort: "目標(コンテナ)ポート",
  servicePort: "サービスポート",
  nodePort: "ノートポート",
  ServiceTypeDesc: {
    ClusterIP: "外部からのアクセスが不可",
    LoadBalancer: "クラウドプロバイダーから提供されたロードバランサーでサービスへアクセス",
    NodePort: "クラスタのノートポートを通してサービスへアクセス"
  },

  useIngress: "Ingress使用"
};
