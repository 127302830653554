<template>
  <div class="login-wrapper">
    <div class="login-container">
      <div class="form-layer">
        <div class="form-container">
          <div class="title">
            <img src="~@/assets/logo.svg" style="width: 40px;"/>
            <div class="title-text">
              <div class="main-title">
                KubeStar
              </div>
              <div class="desc">Bring Cloud Native to Enterprises</div>
            </div>
            <language-select class="set-language"/>
          </div>
          <router-view/>
        </div>
      </div>
      <div class="introduce-container">
        <div>
          <div class="title">Welcome to KubeStar</div>
          <div class="desc">
            {{ $t("aboutUs") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",

  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.introduce-container {
  width: 380px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(to bottom, #1488cc, #2b32b2);
  z-index: 999;

  & > div {
    color: white;
    text-align: center;
    padding: 0 20px;
    width: 380px;
    box-sizing: border-box;
    position: absolute;
    color: #fff;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    .title {
      line-height: 40px;
      font-size: 26px;
      color: #fff;
      font-weight: normal;
      margin-bottom: 20px;
    }

    .desc {
      color: #fff;
      font-size: 14px;
      line-height: 30px;
      margin-bottom: 40px;
    }
  }
}

.form-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 620px;
  height: 100%;
  @include flex(center);

  .form-container {
    width: 500px;

    .title {
      color: $color-main;
      margin-bottom: 40px;
      font-weight: 700;
      @include flex(flex-start, center);

      .title-text {
        margin-left: 10px;

        .main-title {
          color: $color-main;
          font-weight: 700;
          font-size: 24px;
          display: inline-block;
        }

        .desc {
          color: $color-sub;
          font-size: 14px;
        }
      }

      .set-language {
        position: absolute;
        top: 16px;

        right: 5px;
        cursor: pointer;
      }
    }

    ::v-deep.login-form-title {
      color: $color-sub;
      margin-bottom: 20px;

      font-size: 18px;
    }
  }
}

.login-wrapper {
  height: 100vh;
  width: 100%;
  background-color: #eaf1fb;
  background-image: url("../../assets/login-background.png");
  background-size: cover;
  overflow: hidden;
  position: relative;

  .login-container {
    position: relative;
    background: #fff;
    border-radius: 8px;
    width: 1000px;
    height: 580px;
    position: absolute;
    overflow: hidden;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}
</style>
