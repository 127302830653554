export default {
  inputDownloadFileName: "请输入下载文件名",
  download: "下载",
  tip: "提示",
  connectionLost: " 🔗 链接已断开，是否要重新连接？",
  reconnect: "重新连接",
  addExecCommand: "添加执行命令",
  addContainerRunCommand: "添加运行命令",
  addContainerRunCommandParam: "添加运行参数"
};
