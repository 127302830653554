export default {
  KubeStarTools: "Tool Set",

  tools: {
    clusterMonitor: "Cluster Monitor",
    clusterMonitorDescription: "Provides multi-dimensional monitoring for the cluster",
    audit: "Audit List",
    auditDescription: "View operation logs",
    importYaml: "One-click Deployment",
    importYamlDescription: "Deploy by importing existing Kubernetes yaml configurations",
    resourceTopology: "Resource Topology",
    costAnalyse: "Cost Analyse",
    costTable: "Cost Statistics",
    resourceTopologyDescription: "Resource topology diagram"
  }
};
