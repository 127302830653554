export default {
  tips: {
    nameCommonPattern: "Required field, allows lowercase letters/numbers/dashes",
    application: "Application name, required field, allows lowercase letters/numbers/dashes",
    replicas: "Number of instances of the application",
    terminationGracePeriodSeconds: "The wait time for the application to exit, after which it will be forcibly stopped",
    podManagementPolicy: "The rules for creating and scaling container groups",
    updateStrategy: "The rolling update strategy for the container group",
    partition:
      "When the rolling update strategy is RollingUpdate, you need to set the shard to specify the stage during the upgrade",
    schedule: "Follow the crontab rules, for example (execute at 2:00 am every day: 0 2 * * *)",
    concurrencyPolicy:
      "Specifies whether to start normally when the previous task has not been completed until the next task time",
    startingDeadlineSeconds: "How many seconds can it be scheduled after missing the scheduling time",
    suspend:
      "Whether to suspend running, if the status is ON, the scheduled tasks will be suspended from executing tasks",
    activeDeadlineSeconds:
      "The maximum running time of the Job. Pods that exceed this time will be killed to prevent Pods from running for a long time",
    backoffLimit: "Number of retries after the Pod fails to run",
    startCommand: "The startup command that the application should execute, usually providing execution parameters",
    postStart:
      "The action to be performed after the application starts can be a script, HTTP address or socket port, usually used to register services",
    preStop:
      "The action to be performed before the application exits can be a script, HTTP address or socket port, usually used to log out services",
    livenessProbe:
      "Detects the health status of the application. The action can be a script, HTTP address, or socket port, usually used to check the application load. If it fails to execute multiple times, the application will be restarted",
    readinessProbe:
      "Detects whether the application is ready. The action can be a script, HTTP address, or socket port, usually used to check whether the application can receive requests. If it fails to execute multiple times, the request will not be forwarded to the application",
    parallelism: "Specifies the maximum concurrency of Pods for a given task at a given point in time",
    completions: "Specifies the number of Pods required to complete the task",
    addlabels: "The format of labels is Key:Val",
    imageText: "This image was not found in the system and only supports manual filling in",
    selectExistImage: "Select the image in the current system image repository",
    labelSearch: "If you search by label, the search condition format is in the form of Key:Val",
    addContainerPorts: "Select the type of port you want to add",
    location: "Select the cluster to be released",
    maxUnavailable: "In rolling update, how many Pods can we tolerate that are unable to provide services",
    maxSurge: "In rolling update, how many additional Pods can there be",
    checkoutNamespace: "After switching Namespce, you need to re-fill in the container group configuration",
    containerConfTooltipText: "Please submit or delete the container group configuration that is being edited",
    serviceConfTooltipText: "Please submit or delete the service configuration that is being edited",
    containerConfigMap: "Use ConfigMap to separate your configuration data from the application code",
    containerSecret:
      "Kubernetes Secrets let you store and manage sensitive information, such as passwords, OAuth tokens, and ssh keys",
    containerVolume:
      "On-disk files in a container are ephemeral, which presents some problems for non-trivial applications when running in containers. One problem is the loss of files when a container crashes. The kubelet restarts the container but with a clean state. A second problem occurs when sharing files between containers running together in a Pod. The Kubernetes volume abstraction solves both of these problems",
    containerPersistentVolume:
      "A PersistentVolume (PV) is a piece of storage in the cluster that has been provisioned by an administrator or dynamically provisioned using Storage Classes. It is a resource in the cluster just like a node is a cluster resource. PVs are volume plugins like Volumes, but have a lifecycle independent of any individual Pod that uses the PV",
    containerLiftCycleTitle: "Define a Command and Arguments for a Container",
    containerLiftCycle:
      "When you create a Pod, you can define a command and arguments for the containers that run in the Pod, The command and arguments that you define cannot be changed after the Pod is created",
    containerHealthCheckTitle: "Configure Liveness, Readiness and Startup Probes",
    appAndService: "Application and service",
    searchHistory: "Search history",
    serviceUnAssociatedAppWarningTitle: "Service is not associated with any application",
    serviceUnAssociatedAppWarningDesc: "Label Selector did not match any application or Pod. Please check and confirm.",
    showMore: "Show more",
    copyResult: "Copy result",
    result: "result",
    copying: "Copying",
    copySuccess: "copy successfully",
    copyFailed: "copy failed",
    LabelSelectorTips: "Please input label，the form is key:value",
    LabelSelectorError: 'The form of input is wrong, no ":" in it'
  },

  empty: "No relevant resources found",
  emptyParams: "No {0} monitoring data",
  emptyInLocation: "No related resource of【{0}】",
  emptyResource: "No record of {0}",
  success: "Success",
  error: "Error",

  albAnnotationsHelp: "CHeck ALB Annotations' guide",
  step: "Steps",
  addClusterStep: {
    1: "Choose cloud provider",
    2: "Fill in configuration and test cluster connection",
    3: "Fill in monitoring configuration",
    4: "Complete"
  },

  addRegistryStep: {
    1: "Choose image registry",
    2: "Fill in configuration and test image registry connection",
    3: "Complete"
  },

  applicationListBatchStep: {
    1: "Please confirm selected application",
    2: "Confirm"
  },

  registryTips: {
    needAuth: "ImagePullSecret will be created automatically when this is enable",
    test: "Test the connection between KubeStar and image registry"
  },

  clusterTips: {
    test: "Test the connection between KubeStar and cluster",
    monitor: "Add monitor client for cluster status visualization"
  },

  moreConf: "More configurations",

  imageTips: "Image format：image registry/image name:tag",

  ingressTips: {
    ingressItemAdd: "Add services for domain names"
  },

  recommand: "Recommended resources based on history",

  addApplication: {
    import: "Unused data detected, do you want to reload them?",
    leavePrompt: "You have unsaved data on this page, are you sure to exit now ?"
  },

  configMapTips: {
    mountPath: "Mount config to the specificed mountPath",
    overlay: "If mounted config files overwrite files existed in this path",
    mode: "The permission of config files mounted "
  },

  receiverTips: {
    wechatCorpID: "Wechat corp account ID, find it in 'My Corps'",
    wechatAgentID: "Third-party corp application ID, find it in ‘My third-party application'",
    wechatApiSecret: "Third-party application access key, find it in 'My third-party application'"
  },

  alarmTips: {
    duration: "Alerts will be triggered if monitoring metrics have exceeds threshold for this duration",
    alarmMessageContent: "Using $value to get current metrics"
  },

  affinityTips: {
    nodeAffinity: "Desire Pod to be scheduled on which node",
    podAffinity: "Desire Pod to be scheduled with which Pod",
    podAntiAffinity: "Desire Pod not to be scheduled with which Pod on which node",
    nodeKey: "Node label key which running Pod and its affinity requiredDuringSchedulingIgnoredDuringExecution Pod",
    nodePreferKey:
      "Node label key which running Pod and its affinity preferredDuringSchedulingIgnoredDuringExecution Pod",
    antiNodeKey: "Node label key which running Pod and anti-affinity Pod"
  },

  imageHostTips: {
    SWRHost: "Host format: swr.{projectName}.myhuaweicloud.com",
    TCRHost: "Host format: {registryName}.tencentcloudcr.com"
  },

  listSelectResourceTips: "Please select resource details to view",
  listHasSelectedApplications: "Selected application",
  searchLabelKey: "Search label",

  timeSelectErrorTips: "Start time couldn't later than end time",
  editAnnotationsTips:
    "Annotation/Label modification will trigger Deployment, Statefulset, Daemonset, Cronjob, Pod restart, but node status no influence",

  createClusterTips:
    "After the cluster is created successfully, which is available by binding user or user groups as well as getting permissions.",
  monitorClusterDeny: "Cluster {0} is not bind by current user or already forbidden",
  copyInvitationInfo: "Organization invitation information has been copied to the pasteboard",
  generateInvitationInfoTips: "Organization invitation information cloud be generated at organization list",
  emptyUser: "Please [create user], there is no user under organization",
  emptyCluster: "Please [create cluster], there is no cluster under organization",
  emptyUserGroup: "Please bind user group or not, there is no user group",
  emptyUserGroupTips:
    "User will get user group permission firstly, if no user group belonging, user will inherit their own permission",
  emptyOrganization: "No organization binding",
  emptyCategory: "No relevent category, [quick creation]",
  oneTimeMFACode: "One-Time PassCode",
  ResetMFAEmailSent: "A verification email to reset MFA has been sent to your registered email address",
  InputMFAResetEmail: "Enter the verification code in the email",

  snapshotDescription: "please input snapshot's description",
  sameSnapshotWarning: "The application and configurations are identical to the latest snapshot, so there's no need to recreate the snapshot.",

  whatIsAKSK: "What's AK/SK",
  AKSKTips: "using AK(Access KeyID), SK(Secret Access Key) for signing requests in command line and OpenAPI access",

  importPanelTips1: "1.Batch importing may change cluster information",
  importPanelTips2: "2.Batch importing won't delete already existed panel",

  createClusterSuccessTips:
    "After the cluster is created successfully, which is available by binding user or user groups as well as getting permissions"
};
