export default {
  application: "アプリケーション",
  Deployment: "ステートレス",
  StatefulSet: "ステートフルアプリ",
  CronJob: "定期ジョブ",
  DaemonSet: "デーモンセット",
  applicationTopo: "アプリケーショントポロジー",

  CostTable: "コスト統計ダッシュボード",

  applicationSteps: {
    first: "基本設定",
    second: "POD設定",
    third: "サービス設定"
  },

  applicationName: "アプリ名称",
  applicationType: "アプリタイプ",

  replicas: "レプリカ(稼働しているPOD)数",
  terminationGracePeriodSeconds: "停止までの待機時間",
  podManagementPolicy: "POD管理ポリシー",
  OrderedReady: "順番作成もしく削除",
  Parallel: "並行作成もしく削除",
  strategy: "アップデータポリシー",
  updateStrategy: "ローリングアップデータポリシー",
  RollingUpdate: "ローリングアップデータ",
  OnDelete: "podが削除された時に実施",
  partition: "パーティション",
  volumeClaimTemplates: "ボリュームクレームテンプレート",
  volumeClaimTemplatesName: "ボリュームクレームテンプレート名",
  schedule: "スケジューリングルール",
  concurrencyPolicy: "並行処理ポリシー",
  startingDeadlineSeconds: "起動の締切制限",
  suspend: "一時停止中",
  activeDeadlineSeconds: "ジョブの最大実施時間",
  backoffLimit: "リトライ回数",
  parallelism: "シングルジョブのPOD並行数",
  completions: "シングルジョブ期待POD完成数",

  containerAdd: "コンテナ追加",
  containerName: "コンテナ名称",
  containerConf: "コンテナ設定",
  containerStatus: "コンテナ状態",

  nodePort: "ノートポート",
  env: "環境変数",
  liftCycle: "ライフサイクル",
  startCommand: "起動コマンド",
  runningParams: "実行パラメータ",
  runningCommand: "実行コマンド",
  postStart: "起動後実施",
  preStop: "停止前実施",
  handleType: "処理方式",
  command: "コマンド",
  exec: "コマンド入力",
  httpRequest: "HTTPリクエスト",
  tcp: "TCPリクエスト",
  health: "ヘルスチェック",
  checkType: "チェック方法",
  livenessProbe: "ヘルスチェックプローブ(livenessProbe)",
  initialDelaySeconds: "初回チェック時間(秒)",
  periodSeconds: "間隔時間(秒)",
  timeoutSeconds: "タイムアウト時間(秒)",
  readinessProbe: "準備チェックプローブ(readinessProbe)",
  successfulJobsHistoryLimit: "成功ジョブの最大保存数",
  failedJobsHistoryLimit: "失敗ジョブの最大保存数",

  podName: "POD名称",
  podIP: "PODのIP",
  hostIP: "ホストIP",
  restartTimes: "リセット回数",
  logs: "アプリのログ",
  terminal: "ターミナル",
  pod: "POD",
  container: "コンテナ",

  scaleByHand: "手動スケール",
  autoScale: "自動スケール",
  autoScaleSwitch: "自動スケールON/OFF",
  autoScaleResource: "自動スケールのリソース",
  cpuThreshold: "CPUのしきい値",
  memoryThreshold: "メモリしきい値",
  replicasRange: "レプリカ範囲",

  enable: "有効",
  disable: "無効",

  podStatus: {
    Initialized: "初期化",
    Ready: "アプリケーションのインスタンスが準備完了",
    ContainersReady: "コンテナが準備完了",
    PodScheduled: "アプリケーションのインスタンスがスケジュールされた"
  },

  hostAliases: "ホストエイリアス",
  alias: "エイリアスリスト",

  lastSchedule: "前回のスケジュール時間",
  activeCronJob: "アクティブのタスク",
  daemonSetCurrent: "現在のインスタンス数",
  daemonSetDesired: "希望するインスタンス数",
  applicationReady: "準備完了",
  updatedReplicas: "アップグレードされたインスタンス",
  readyReplicas: "準備完了インスタンス",
  availableReplicas: "使用可能インスタンス",
  unavailableReplicas: "使用不可インスタンス",
  currentReplicas: "現在インスタンス",
  currentRevision: "現在バージョン",
  updateRevision: "バージョンアップ",
  collisionCount: "コリジョン回数",

  currentNumberScheduled: "現在スケジュールされたインスタンス",
  numberMisscheduled: "スケジュールされてないインスタンス",
  desiredNumberScheduled: "希望スケジュールインスタンス",
  numberReady: "準備完了インスタンス",
  observedGeneration: "最新バージョン",
  updatedNumberScheduled: "バージョンアップ済みインスタンス数",
  numberAvailable: "使用可能インスタンス数",
  numberUnavailable: "使用不可インスタンス数",

  nodeAffinity: "Node親和性スケジュール",
  podAffinity: "POD硬親和性スケジュール",
  podPreferAffinity: "POD软親和性スケジュール",
  podAntiAffinity: "POD非親和性スケジュール",
  podRequireTopologyKey: "POD硬親和性TopologyKey",
  podPreferTopologyKey: "POD软親和性TopologyKey",
  podPreferWeight: "POD软親和性ウェイト",
  affinityNodeKey: "Nodeラベルキー",

  cpuOversell: "CPUオバー比例",
  memOversell: "メモリオバー比例",

  logStartTime: "ログ開始時間",
  prePodLogs: "停止した上のコンテイナのログに戻る",
  tailLines: "最近のログレコード数",

  snapshot: "スナップショット",
  createSnapshot: "スナップショットを作成する",

  snapshotList: "スナップショットリスト",
  checkSnapshot: "スナップショットを表示する",

  snapshotConfigMap: "コンフィグ設定({0})",
  snapshotSecret: "シークレット({0})"
};
