export default {
  ConfigMap: "配置",
  configMapName: "配置名称",
  configMapRootPath: "配置绝对路径",
  mountPath: "挂载路径",
  configMapItem: "配置项",
  mountContainer: "挂载容器组：",
  mountFile: "挂载文件",
  fileContent: "文件内容",
  overlay: "是否覆盖",
  mode: "读写权限",

  configmapType: "类型",
  configmapTextDataType: "文本类型",
  configmapBinaryDataType: "二进制类型",
  configmapTypeAffect: "类型会影响提交时配置项的编码方式与配置项是否可编辑",
  configmapTypeUse: "文本类型适用于文本等普通配置文件，二进制类型适用于证书等二进制文件",
  configmapTextDesc: "在提交时不会经过base64转换，配置项的值允许手动修改 ",
  configmapTextDesc2: "基本类型，适用于文本等普通配置文件",
  configmapBinaryDesc: "在提交时经过base64转换，配置项的值不允许手动修改 ",
  configmapBinaryDesc2: "二进制类型(base64类型存储)，适用于证书等二进制文件 ",
  configmapFileSizeLimit: "文件大小不能大于1MB ",
  fileAlreadyExist: "文件已存在，请检查后重新提交",
  currentDataType: "当前配置文件类型为:",

  configmapUpdateSuccess: "配置修改成功，请重启相应Pod以生效"
};
