<template>
  <div></div>
</template>

<script>
import {
  Bullet,
  Area,
  RingProgress,
  Line,
  TinyArea,
  TinyLine,
  Radar,
  Pie,
  Bar,
  GroupedColumn,
  MeterGauge,
  StackedBar,
  Donut,
} from "@antv/g2plot";

//Column
//Gauge

export default {
  name: "G2Plot",

  props: {
    type: {
      type: String
    },
    option: {
      type: Object
    }
  },

  data() {
    return {
      plot: null
    };
  },

  methods: {
    initChart() {
      switch (this.type) {
        case "Bullet":
          this.plot = new Bullet(this.$el, this.option);
          break;

        case "Line":
          this.plot = new Line(this.$el, this.option);
          break;

        case "Area":
          this.plot = new Area(this.$el, this.option);
          break;

        case "RingProgress":
          this.plot = new RingProgress(this.$el, this.option);
          break;

        case "Donut":
          this.plot = new Donut(this.$el, this.option);
          break;

        case "TinyArea":
          this.plot = new TinyArea(this.$el, this.option);
          break;

        case "TinyLine":
          this.plot = new TinyLine(this.$el, this.option);
          break;

        case "Radar":
          this.plot = new Radar(this.$el, this.option);
          break;

        case "GroupedColumn":
          this.plot = new GroupedColumn(this.$el, this.option);
          break;

        case "MeterGauge":
          this.plot = new MeterGauge(this.$el, this.option);
          break;

        case "StackedBar":
          this.plot = new StackedBar(this.$el, this.option);
          break;

        case "Pie":
          this.plot = new Pie(this.$el, this.option);
          break;

        case "Bar":
          this.plot = new Bar(this.$el, this.option);
          break;
      }

      this.plot.render();
    }
  },

  mounted() {
    this.initChart();
  },

  beforeDestroy() {
    this.plot && this.plot.destroy();
  }
};
</script>

<style></style>
