var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-button',{attrs:{"type":"primary","size":"small","plain":""},on:{"click":function($event){return _vm.data.push({ name: '', value: '' })}}},[_vm._v(" "+_vm._s(_vm.$t("handle.envAddKeyVal"))+" ")]),_c('el-button',{attrs:{"type":"primary","size":"small","plain":""},on:{"click":function($event){return _vm.data.push({ name: '', valueFrom: { configMapKeyRef: { name: '', key: '' } } })}}},[_vm._v(" "+_vm._s(_vm.$t("handle.envAddKeyFrom"))+" ")]),_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"item-input",staticStyle:{"margin-top":"10px"}},[('valueFrom' in item)?_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":6}},[_c('el-input',{attrs:{"size":"small"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v(" Name ")]},proxy:true}],null,true),model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.name"}})],1),_c('el-col',{attrs:{"span":4}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":""},on:{"change":value => {
              _vm.$set(item, 'valueFrom', {});
              _vm.$set(item.valueFrom, value, { name: '', key: '' });
            }},model:{value:(Object.keys(item.valueFrom)[0]),callback:function ($$v) {_vm.$set(Object.keys(item.valueFrom), 0, $$v)},expression:"Object.keys(item.valueFrom)[0]"}},[_c('el-option',{attrs:{"label":"ConfigMap","value":"configMapKeyRef"}}),_c('el-option',{attrs:{"label":"Secret","value":"secretKeyRef"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":""},on:{"change":value => {
              _vm.$set(item.valueFrom[Object.keys(item.valueFrom)[0]], 'key', '');
            }},model:{value:(item.valueFrom[Object.keys(item.valueFrom)[0]].name),callback:function ($$v) {_vm.$set(item.valueFrom[Object.keys(item.valueFrom)[0]], "name", $$v)},expression:"item.valueFrom[Object.keys(item.valueFrom)[0]].name"}},[(Object.keys(item.valueFrom)[0] == 'configMapKeyRef')?_c('div',_vm._l((_vm.configMapList),function(item){return _c('el-option',{key:item.metadata.name,attrs:{"label":item.metadata.name,"value":item.metadata.name}})}),1):_c('div',_vm._l((_vm.secretList),function(item){return _c('el-option',{key:item.metadata.name,attrs:{"label":item.metadata.name,"value":item.metadata.name}})}),1)])],1),_c('el-col',{attrs:{"span":6}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":""},model:{value:(item.valueFrom[Object.keys(item.valueFrom)[0]].key),callback:function ($$v) {_vm.$set(item.valueFrom[Object.keys(item.valueFrom)[0]], "key", $$v)},expression:"item.valueFrom[Object.keys(item.valueFrom)[0]].key"}},_vm._l((_vm.mapKeys(
              Object.keys(item.valueFrom)[0],
              item.valueFrom[Object.keys(item.valueFrom)[0]].name
            )),function(key){return _c('el-option',{key:key,attrs:{"label":key,"value":key}})}),1)],1),_c('el-col',{attrs:{"span":2}},[_c('el-button',{attrs:{"icon":"el-icon-delete","circle":""},on:{"click":function($event){return _vm.data.splice(index, 1)}}})],1)],1):_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":11}},[_c('el-input',{attrs:{"size":"small"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v(" Name ")]},proxy:true}],null,true),model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.name"}})],1),_c('el-col',{attrs:{"span":11}},[_c('el-input',{attrs:{"size":"small"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v(" Value ")]},proxy:true}],null,true),model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.value"}})],1),_c('el-col',{attrs:{"span":2}},[_c('el-button',{attrs:{"icon":"el-icon-delete","circle":""},on:{"click":function($event){return _vm.data.splice(index, 1)}}})],1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }