export default {
  loginBtn: "ログイン",
  registerBtn: "登録",
  enterUserName: "アカウント",
  enterPassword: "パスワード",
  enterEmail: "Email",
  passwordConfirm: "パスワード確認",
  registerLink: "新規登録",
  loginLink: "既に登録済み？ログインへ",
  logout: "ログアウト",
  reLogin: "再ログイン",

  toLogin: "ログインへ",
  toRegister: "登録へ",

  enterValidateCode: "認証コードを入力してください"
};
