export default {
  handle: {
    submit: "提交",
    detail: "详细信息",
    confirm: "确认",
    cancel: "取消",
    handle: "操作",
    edit: "编辑",
    delete: "删除",
    add: "添加",
    create: "创建",
    save: "保存",
    publish: "发布",
    search: "搜索",
    submitAgain: "再次发布",
    backLast: "返回上一页",
    backHome: "返回首页",
    backLogin: "登录",
    clusterTest: "集群连接性测试",
    resume: "恢复",
    pause: "暂停",
    registryTest: "镜像仓库连接性测试",
    addCluster: "添加集群",
    addRegistry: "添加镜像仓库",
    restart: "重启",
    update: "升级",
    rollback: "回滚",
    hotfix: "热修改",
    scale: "扩容",
    collect: "收藏",
    cancelCollect: "取消收藏",
    permission: "配置权限",
    addNodePort: "添加开放端口",
    addService: "添加服务",
    toRollback: "回滚到此版本",
    toYAML: "生成YAML对比",
    test: "测试",
    testing: "测试中",
    nextStep: "下一步",
    lastStep: "上一步",
    envAddKeyVal: "添加环境变量",
    envAddKeyFrom: "添加配置文件/密钥引用",
    nodeResume: "恢复调度",
    nodePause: "停止调度",
    nodeDrain: "驱逐节点",
    editMonitor: "编辑监控信息",
    refresh: "刷新",
    moreHandle: "更多操作",
    import: "导入",
    cancelImport: "取消导入",
    toLogs: "查看日志",
    toTerminal: "操作终端",
    aboutUs: "关于",
    quickCreate: "[快捷创建]",
    configMapAddItem: "添加配置项挂载",
    addHostAlias: "新增HOST别名",
    addAlias: "新增别名",
    upload: "上传文件",
    batchUpload: "批量上传文件",
    input: "填写文本",
    openRefresh: "开启自动刷新",
    closeRefresh: "停止自动刷新",
    addAnnotation: "新增注解",
    addReceiver: "添加接收方",
    addEmailConf: "添加邮件设置",
    addSlackConf: "添加Slack设置",
    addWeChatConf: "添加WeChat设置",
    addPhoneConf: "添加电话/短信设置",
    addPhoneNumber: "添加电话号码",
    moreConf: "查看更多",
    slideUpConf: "收起配置",
    addSilenceConf: "添加静默设置",
    reCreate: "重新创建",
    addSilenceLabel: "添加静默标签",
    addAlarmRules: "添加告警规则",
    addNodeLabels: "添加目标节点标签",
    addNodeAffinityReq: "添加节点硬亲和策略",
    addNodeAffinityPrefer: "添加节点软亲和策略",
    addMatchExpression: "添加MatchExpression",
    addMatchField: "添加MatchField",
    addLabelSelector: "添加LabelSelector",

    addPodAffinityLabels: "添加亲和Pod硬策略信息",
    addPodPreferAffinityLabels: "添加亲和Pod软策略信息",
    addPodAntiAffinityLabels: "添加反亲和Pod信息",
    searchService: "查找服务",
    addToleration: "添加容忍规则",
    batchScaleByHand: "批量手动扩容",
    batchScaleAuto: "批量自动扩容",
    batchDelete: "批量删除",
    batchHandle: "批量操作",
    addMetricTemplate: "添加监控采集入口",
    addMetricTemplateBlack: "黑盒监控",
    addMetricTemplateWhite: "白盒监控",
    clickConf: "点击查看",
    generateInvitationInfo: "生成邀请信息",
    copy: "复制",
    batchImport: "批量导入",
    hideText: "隐藏内容",
    displayText: "展示内容",
    inputByHand: "手动输入",
    inputBySelect: "下拉选择",

    rollbackNow: "一键回滚",
    currentVersion: "当前版本"
  }
};
