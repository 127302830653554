export default {
    Cluster: "Clusters",
    Node: "Node",
    currentSelectCluster: "Current select cluster",
    selectClusterAndNamespace: "Please select cluster and namespace",
    nodeName: "Node name",
    nodeList: "Node list",
    clusterKind: "Cluster type",
    clusterResource: "Cluster resource usage",
    nodeResource: "Node resource status",
    hotDeployment: "Hot deployment",
    monitor: "Monitor",
    security: "Security",
    storage: "Storage",
    namespace: "Namespace",
    install: "Install",
    createTime: "Runtime",
    clusterTopoTarget: {
        cpuUsagePercent: "CPU usage percent",
        memUsagePercent: "Memory usage percent",
        diskUsagePercent: "Disk usage percent",
        networkReceivePercent: "Network receive percent",
        networkTransmitPercent: "Network transmit percent",
        cpuUsageTotal: "CPU usage in total(core)",
        memWorkingBytes: "Memory usage in total(GiB)"
    },
    clusterInfo: "Cluster Detail",
    clusterConfig: "Cluster Config",
    addClusterConfig: "Add Config",
    clusterMaxStringTips: "Max Text length more than 5000 characters",

    JSONSecret: "JSON Secret key",

    kubeletVersion: "Kubelet version",
    osImage: "OS Image",
    kernelVersion: "Kernel version",
    kubeProxyVersion: "KubeProxy version",
    containerRuntimeVersion: "Container runtime",
    architecture: "Computing architecture",

    taints: "Taints",
    taintsDesc: "Taints：taints allow a node to repel a set of pods",
    taintsNoSchedule:
        "NoSchedule(Do not allow new pods to schedule onto the node unless they tolerate the taint, allow all already-running pods to continue running.)",
    taintsPreferNoSchedule: "PreferNoSchedule(The scheduler tries not to schedule new pods onto the node)",
    taintsNoExecute: "NoExecute(Evict any already-running pods that do not tolerate the taint)",
    taintsDefault: "Default(null)",

    tolerations: "Tolerations",
    tolerationsDesc: "Tolerations allow (but do not require) the pods to schedule onto nodes with matching taints.",
    belongCluster: "Cluster",

    involvedObjectKind: "event's involved object kind",
    involvedObjectNamespace: "event's involved object namespace",
    involvedObjectName: "event's involved object name",
    eventComponent: "event's component",
    eventReason: "event's reason",
    eventType: "event's type",
    eventHost: "event's Host",
    clusterEvent: "Cluster's Event"
};
