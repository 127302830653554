import axios from "axios";
import store from "@/store/index";
import i18n from "@/lang";
import { Notification, MessageBox } from "element-ui";
import { debounce } from "lodash";
import router from "@/router";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

http.defaults.headers.post["Content-Type"] = "application/json";

// 添加请求拦截器
http.interceptors.request.use(
  config => {
    if (store.state.app.token) {
      config.headers["X-KubeStar-Token"] = store.state.app.token;
      config.headers["X-KubeStar-UserID"] = store.state.app.userId;
      config.headers["X-KubeStar-UserName"] = store.state.app.userName;
    }

    if (store.state.app.organization) {
      config.headers["X-KubeStar-Organization"] = store.state.app.organization;
    }

    // config.cancelToken = new axios.CancelToken(cancel => {
    //   store.commit("app/PUSH_CANCEL_TOKEN", { cancelToken: cancel });
    // });

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

let alert = debounce(() => {
  MessageBox.alert(i18n.t("tokenError"), i18n.t("prompt"), {
    confirmButtonText: i18n.t("reLogin"),
    callback: () => {
      store.dispatch("app/logout").then(() => {
        location.reload();
      });
    }
  });
}, 2000);

http.interceptors.response.use(response => {

    if (response.data.code === 9998) {
        store.dispatch("app/logout")
        router.push({path: "/login"}).catch(err => {})
        return Promise.reject(new Error("Token Error"));
    }

    let whiteList = ["/apidocs.json", "/monitor.kubestar.io/notification/alert/metrics"];

    if (response.data.code === 9996) {
        Notification.warning({
            title: "Warning",
            message: `
            <div style="width: 100%; word-wrap: break-word;">
            <div class="title" style="font-size: 14px;">
                ${response.config.url}<br />
            </div>
            <div class="desc">
                ${responseMessageTrans(response.data)}
            </div>
            </div>
            `,
            dangerouslyUseHTMLString: true,
            duration: 60000
        })
    } else if (response.data.code !== 0 && !whiteList.includes(response.config.url)) {
        Notification.error({
            title: "Error",
            message: `
        <div style="width: 100%; word-wrap: break-word;">
        <div class="title" style="font-size: 14px;">
           ${response.config.url}<br />
        </div>
        <div class="desc">
        ${responseMessageTrans(response.data)}
        </div>
        </div>
        `,
            dangerouslyUseHTMLString: true,
            duration: 60000
        });
    }

    return response.data;
  },
  error => {
    return Promise.reject(error);
  }
);

function responseMessageTrans(data) {
  let { code, message } = data;
  let resultMessage = "";

  switch (code) {
    case 9997:
      resultMessage = "Permission denied， please contact with administrator";
      break;

    case 9996:
      resultMessage = "User doesn't bind any cluster";
      break;

    default:
      resultMessage = message;
  }

  return resultMessage;
}

export default http;
