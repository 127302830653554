<template>
  <div class="layout-top">
    <main-header />
    <app-main class="app-main" />
  </div>
</template>

<script>
import MainHeader from "./components/Header.vue";
import AppMain from "./components/AppMain.vue";

export default {
  name: "layout",
  components: {
    MainHeader,
    AppMain
  }
};
</script>

<style lang="scss" scoped>
.layout-top {
  height: 100%;
  .app-main {
    padding: 55px 10px 10px 10px;
    min-width: 1400px;
    box-sizing: border-box;
    height: 100%;
    //margin: 50px auto 0;
    //position: relative;
    //overflow: auto;
  }
}
</style>
