import router from "./index.js";
import store from "../store/index";
import {getCookies} from "@/utils/cookies";
import {Loading} from "element-ui";
import {isEmpty} from "lodash";

import NProgress from "nprogress";
import "nprogress/nprogress.css";
import {initOrganizationList} from "@/pages/Login/login";

NProgress.configure({showSpinner: false});

const whiteList = [
    new RegExp("/login/*"),
];

router.beforeEach(async (to, from, next) => {

    NProgress.start();

    const hasToken = getCookies("X-KubeStar-Token");

    if (hasToken) {

        if (to.path === "/login") {

            next(`/home`);

        } else if (isEmpty(store.state.app.userInfo)) {

            await initBackEndControlRoutes()

            next({...to, replace: true});

        } else {

            // 添加最近访问的资源
            if (to.name === "Detail") await store.dispatch("app/setRecordList", to);

            next();
        }

    } else {
        // 检查路由是否在白名单中
        const isWhiteListed = whiteList.some(pattern => pattern.test(to.path))

        if (isWhiteListed) {
            next();
        } else {
            next(`/login`);
        }
    }
    //store.commit("app/CLEAR_CANCEL_TOKEN");
    //await store.dispatch("config/setConfigs");
})

router.afterEach(() => {
    NProgress.done();
})

export async function initBackEndControlRoutes() {

    const loading = Loading.service({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        fullscreen: true
    });

    if (store.state.app.organizationList.length < 1) {
        await initOrganizationList();
    }

    let {role} = await store.dispatch("app/getUserInfo");

    const accessRoutes = await store.dispatch("permission/generateRoutes", role);

    accessRoutes.forEach(item => {
        router.addRoute(item);
    });

    router.addRoute({
        path: "*",
        redirect: "/notFound"
    });

    loading.close();
}