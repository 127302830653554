export default {
  appStore: "应用商店",
  appStoreDesc: "KubeStar官方应用商店，基于Helm Charts，支持上传Charts，用户可以根据需求快速一键部署",
  uploadToAppStore: "上传应用至应用商店",
  uploadTipsDesc: "KubeStar应用商店支持上传Charts，点击下方的上传按钮，即可上传Charts文件",
  clickToUploadTips: "点击上传",
  searchApp: "查找应用",
  install: "安装",
  cluster: "集群",
  namespace: "命名空间",
  version: "版本",
  releaseName: "发布名称",
  addHelmParams: "添加参数",
};
