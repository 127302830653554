export default {
  imageSelect: "Select image",
  inputImageManually: "Input Image Manually",
  imageSelected: "Selected",
  imageTagName: "Image tag",
  imageConfirm: "You haven’t chosen image/image tag, still exit?",
  currentRegistry: "Current image registry: {0}",
  currentImage: "Current image: {0}",
  currentImageTag: "Current image tag: {0}",
  imageName: "Image name",
  imageTag: "Image Tag",
  needAuth: "Need authentication？",
  registry: "Image registry",
  ca: "CA certificate",

  uCloudAPIKeyHelp:
    'Access the UCLOUD API public key, which can be obtained from the UCLOUD <a href="https://console.ucloud.cn/uapi/apikey">console</a>.',
  uHubProjectID: "Project ID, if it's the default project, it cannot be empty in other cases.",
  pullImagePasswd:
    "The password used when pulling the image, you may have set a separate password, please confirm and fill it in.",

  appImageSetting: "Pulling private image setting",
  chooseKey: "Select Pull Image Secret",
  ifNoPullImageSecret: "If there is no target secret, you can ",
  createPullImageSecretNow: "Create secret now",
  createPullImageSecret: "Create secret",
  createPullImageSecretHit: "The secret will be created in the cluster and namespace you selected, ",
  createPullImageSecretHostHit:
    "Do not include 'http' or 'https' at the beginning, simply enter the domain name or IP address."
};
