export default {
  Ingress: "Ingresses",
  ingressName: "Ingress name",
  useHTTPS: "HTTPS",
  ingressSubHost: "Sub path",
  ingressRules: "Ingress route rules",
  ingressHint:
    'Hint: To view the details of Services and Pods on the topology diagram, right-click and select a specific card, then click "View Resource Details" to navigate to the corresponding resource details page.',
  viewResourceDetail: "View resource details",
  copyResourceName: "Copy resource name",
  copyResourceNameSuccess: "Copy resource name successfully",

  ingressClassController: "IngressClass Controller",
  parametersAPIGroup: "Parameters.apiGroup",
  parametersKind: "Parameters.Kind",
  parametersName: "Parameters.Name",
  parametersNamespace: "Parameters.Namespace",
  parametersScope: "Parameters.Scope"
};
