import http from "../http";

export const secretList = params => {
  return http({
    method: "get",
    params,
    url: `/k8s.kubestar.io/secrets`
  });
};

export const secretListByLocation = (data, params) => {
  return http({
    method: "get",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/secrets`,
    params
  });
};

export const secretSubmit = (data, clusterLoc) => {
  data.apiVersion = "v1"
  data.kind = "Secret"
  return http({
    method: "post",
    url: `/k8s.kubestar.io/vendors/${clusterLoc.vendor}/regions/${clusterLoc.region}/clusters/${clusterLoc.cluster}/namespaces/${data.metadata.namespace}/secrets`,
    data
  });
};


export const secretCopy = (namespace, data, clusterLoc) => {
  data.apiVersion = "v1"
  data.kind = "Secret"
  return http({
    method: "put",
    url: `/k8s.kubestar.io/vendors/${clusterLoc.vendor}/regions/${clusterLoc.region}/clusters/${clusterLoc.cluster}/namespaces/${namespace}/secrets`,
    data
  });
};

export const secretDetail = data => {
  return http({
    method: "get",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/secrets/${data.secret}`
  });
};

export const secretUpdate = (data, clusterLoc) => {
  data.apiVersion = "v1"
  data.kind = "Secret"
  return http({
    method: "put",
    url: `/k8s.kubestar.io/vendors/${clusterLoc.vendor}/regions/${clusterLoc.region}/clusters/${clusterLoc.cluster}/namespaces/${data.metadata.namespace}/secrets/${data.metadata.name}`,
    data,
  });
};

export const secretDelete = data => {
  return http({
    method: "delete",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/secrets/${data.name}`
  });
};

export const createImagePullSecrets = (data, namespace, headers) => {
  return http({
    method: "post",
    url: `/k8s.kubestar.io/namespaces/${namespace}/registry/secrets`,
    data,
    headers
  });
};

