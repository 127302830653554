export default {
    appStore: "App Store",
    appStoreDesc: "The official KubeStar App Store, based on Helm Charts, supports uploading Charts. Users can quickly deploy with one click according to their needs",
    uploadToAppStore: "Upload application to the App Store",
    uploadTipsDesc: "The KubeStar App Store supports uploading Charts. Click the upload button below to upload Charts files",
    clickToUploadTips: "Click to upload",
    searchApp: "Search for applications",
    install: "Install",
    cluster: "Cluster",
    namespace: "Namespace",
    version: "Version",
    releaseName: "Release Name",
    addHelmParams: "Add parameters",
};
