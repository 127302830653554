import http from "../http";

export const labelList = params => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/labels`
  });
};

export const batchAnnotations = (payload, data) => {
  return http({
    method: "put",
    url: `/crd.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/resources/annotations`,
    data
  });
};

// edit labels
export const batchLabels = (payload, data) => {
  return http({
    method: "put",
    url: `/crd.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/resources/labels`,
    data
  });
};
