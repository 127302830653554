export default {
  clusterMonitorDetail: "集群监控配置",
  monitor: "监控",
  monitorType: "监控类型",
  monitorReady: "启用监控配置",
  monitorHost: "服务器地址",
  clusterMonitor: "集群监控",
  namespaceMonitor: "命名空间监控",
  currentMonthCost: "本月成本估算",
  previousMonthCost: "上月成本估算",
  masterComponentsStatus: "Master组件状态",
  nodeStatus: "节点状态",
  nodeAbstract: "节点概要",
  kubernetesSummary: "资源总览",
  namespaceRank: "Namespace用量排行",
  deploymentRank: "Deployment 用量排行",
  statefulSetRank: "StatefulSet 用量排行",
  cronJobRank: "CronJob 用量排行",
  daemonSetRank: "DaemonSet 用量排行",
  rankByCPU: "按CPU排行",
  rankByMemory: "按内存排行",
  costDashboard: "成本中心",

  podAlias: "Pod别名",
  nodeAlias: "Node别名",
  kubeletAlias: "Kubelet别名",
  podAliasDesc:
    "不同的Prometheus版本或者采集器（exporter）收集的元数据标签不同，一般来说，'pod' 和 'pod_name' 都是 Kubernetes 环境中使用的元数据标签，默认pod。",
  nodeAliasDesc:
    "不同的Prometheus版本或者采集器（exporter）收集的元数据标签不同，一般来说，'node' 和 'node_name' 都是 Kubernetes 环境中使用的元数据标签，默认node。",
  kubeletAliasDesc:
    "不同的Prometheus版本或者采集器（exporter）收集的元数据标签不同，一般来说，'cadvisor'和'kubelet' 都可能为 Kubernetes 环境中使用的元数据标签，默认kubelet。",

  tenantConfig: "多租户配置",
  thanosConfig: "Thanos设置",
  thanosAdditionLabel:
    "当使用Thanos或Prometheus从多个集群收集监控数据时，为了区分不同的集群，通常会使用额外唯一的元数据标签Key/Value。",
  uniqueLabelKey: "唯一Label Key",
  uniqueLabelValue: "唯一Label Value",
  thanosDesc:
    "多租户情况下，使用Thanos Receiver 架构需要标明监控数据的租户信息，数据来源于 receiver 组件的 default-tenant-id 或者 tenant-label-name",
  thanosLabelDesc: "Thanos架构下，Prometheus的唯一性标示 Label",

  costDashboardDesc: "成本分析",
  clusterMonitorDesc: "多维集群监控",

  timeRange: "时间范围",
  customTimeRange: "自定义时间范围",
  startTime: "开始时间",
  endTime: "结束时间",
  clusterMonitorConf: "集群监控配置",
  clusterMonitorConfDescription: "配置集群的监控源/地址/开关等信息",

  customMonitor: "自定义监控",
  customMonitorPanel: "自定义监控面板",
  createDashboard: "创建Dashboard",
  dashboardName: "Dashboard名称",
  dashboardCategory: "Dashboard分类",
  createCategory: "创建分类",
  categoryName: "分类名称",
  allCategory: "全部分类",

  selectPrometheusOrigin: "选择Prometheus所属的集群",
  addClusterMonitor: "添加集群监控",
  editPanel: "编辑面板",
  addQuery: "添加查询条件",
  panelSetting: "面板设置",
  panelTitleSetting: "标题设置",
  panelTitle: "图表标题",
  panelDescription: "图表描述",
  panelVisualization: "图表类型",
  panelDisplay: "显示设置",
  openArea: "显示面积图(Line类型)",
  panelThreshold: "阈值设置",
  threshold: "阈值",
  panelDangerValue: "危险值",

  panelQuery: "查询条件",
  promQLQuickGenerate: "快捷生成PromQL",
  legendFormat: "图例显示",
  legendFormatTooltipText: "使用 {{}} 设置图例显示的字段，例如使用 {{pod}} 显示POD的名称",

  promQLGenerator: "PromQL生成器",
  promQLSelectMetric: "选择指标",
  promQLAggrFn: "聚合函数",
  instantValue: "瞬时",
  intervalValue: "区间",
  aggrFn: {
    sum: "求和",
    count: "元素个数",
    rate: "平均增长率",
    irate: "增长率",
    increase: "增长量",
    min: "最小值",
    max: "最大值",
    avg: "平均值",
    topk: "最大的 k 个元素",
    bottomk: " 最小的 k 个元素"
  },
  promQLAggrBy: "聚合纬度",
  promQLAggrTips1: "by用于从计算结果中保留列举的标签，而移除其它标签。without则正好相反，结果向量中只移除列出的标签",

  promQLAggrTips2: "例：sum(http_requests_total) by (code,handler) 保留数据中的code,handler字段",

  promQLTimerange: "区间采样值",
  promQLKValue: "K值",
  promQLLabelFilter: "Label过滤条件",
  promQLAddLabel: "添加Label",
  promQLLabelTips1: "标签(Label)反映了当前样本的特征维度，通过这些维度Prometheus可以对样本数据进行过滤等",
  promQLLabelTips2: " 例：api_http_requests_total{method='POST', handler='/messages'}",

  promQLExpression: {
    equal: "完全相等",
    notEqual: "不相等",
    regular: "正则表达式"
  }
};
