import { constantRoutes, asyncRoutes } from "@/router/index.js";
import { includes } from "lodash";

const state = {
  routes: [],
  addRoutes: []
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};

const actions = {
  generateRoutes({ commit }, role) {

    let accessRoutes = [];

    return new Promise(resolve => {

      if (includes(["organization-admin", "system-admin"], role)) accessRoutes = asyncRoutes;

      commit("SET_ROUTES", accessRoutes);

      resolve(accessRoutes);
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
