export default {
  costCloudFactoryProportionYearly: "Cloud vendor consumption ratio for this year",
  costCloudFactoryProportionSeason: "Cloud vendor consumption ratio for this quarter",
  costCloudFactoryProportionMonthly: "Cloud vendor consumption ratio for this month",

  costCloudAccountProportionYearly: "Cloud account consumption ratio for this year",
  costCloudAccountProportionSeason: "Cloud account consumption ratio for this quarter",
  costCloudAccountProportionMonthly: "Cloud account consumption ratio for this month",

  costCloudServiceProportionYearly: "Cloud service consumption ratio for this year",
  costCloudServiceProportionSeason: "Cloud service consumption ratio for this quarter",
  costCloudServiceProportionMonthly: "Cloud service consumption ratio for this month",

  costTrendYearly: "Annual consumption trend",
  costTrendSeason: "Quarterly consumption trend",
  costTrendMonthly: "Monthly consumption trend",

  yearly: "Annually",
  season: "Season",
  monthly: "Monthly",

  costCloudServiceUsageDetailYearly: "Cloud service usage details for the current year",
  costCloudServiceUsageDetailSeason: "Cloud service usage details for the current quarter",
  costCloudServiceUsageDetailMonthly: "Cloud service usage details for the current month",

  ranking: "Ranking",
  serviceName: "Service Name",
  cloudAccount: "Cloud Account",
  cost: "Cost",

  costTotalYearly: "Total consumption for this year",
  costTotalSeason: "Total consumption for this quarter",
  costTotalMonthly: "Total consumption for this month",

  costAnalyses: {
    costDetails: "Cost Details",
    currentCost: "Current Cost",
    predictedCost: "Predicted Cost",
    costTrends: "Cost Trends",
    cloudServiceCostsTop5: "Cloud Service Costs Top 5",
    cloudServiceGrowthRatesTop5: "Cloud Service Growth Rates Top 5",

    tableCloudAccount: "Account",
    tableCloudName: "Cloud Name",
    tableCloudCost: "Current Cost",
    tableCloudPredictedCosts: "Cloud Predicted Costs",

    tableIncreasedPercentAccount: "Account",
    tableIncreasedPercentService: "Service Name",
    tableIncreasedPercentIncreasePercent: "Growth Rate",
    tableIncreasedPercentcostCurrent: "Current Cost",

    dns: "DNS",
    loadBalance: "Load Balance",
    eks: "EKS",
    gke: "GKE",
    crossCloudService: "Cross Cloud Service",
    backEndService: "Back End Service",
    exportGateway: "Export Gateway"
  }
};
