export default {
    Gateway: "Gateway",
    GatewayClass: "GatewayClass",
    HTTPRoute: "HTTP Route",
    gatewayName: "Gateway name",
    gatewayClassName: "Gateway class",
    gatewayAddress: "Gateway address",
    gatewayStatus: "Gateway status",

    gatewayBasicInformation: "Gateway Basic information",

    gatewayClassController: "Controller",
    gatewayClassStatus: "Status",
    gatewayClassDesc: "Description",

    httpRouteHostNames: "HostName",

    rule: "Rule",
    httpRouteMatch: "HTTP Route Matches",
    pathMatch: {
        Exact: "Exact match",
        PathPrefix: "Path prefix match",
        RegularExpression: "Regular expression match"
    }


};
