export default {
  iam: "IAM management",
  organizationList: "Organization list",
  userGroupList: "User group list",
  roleList: "Role list",
  userList: "User list",
  contacter: "Contacter",
  contacterEmail: "Contacter email",
  organizationAlias: "Organization alias",
  invitationInfo: "Invitation information",
  organizationID: "Organization ID",
  inviterID: "Inviter ID",
  invitationCode: "Invitation code",
  createOrganization: "Create organization",
  joinOrganization: "Join in organization",
  belongOrganization: "Organization",
  userGroupAddUser: "Add user in this user group",
  userGroupPermission: "User group permission",
  createUser: "Create user ",
  rolePermission: "Role permission ",
  roleAllPermission: "All permisson",
  userPermission: "User permission",
  resetAKSK: "Reset AK/SK",
  userID: "User ID ",
  bindUserGroup: "Bind user group ",
  createUserGroup: "Create user group",
  lastLoginTime: "Last Login Time",


  AccessControl: "Access Control",
  Secrets: "Secrets",
  ImagePullSecrets: "Image Pull Secrets",
  AutomountToken: "Auto Mount Token",
  Roles: "Roles",
  RoleBindings: "Role Bindings",
  ClusterRoles: "ClusterRoles",
  ClusterRoleBindings: "ClusterRole Bindings",
  RoleBindingName: "RoleBinding Name",
  ClusterRoleBindingName: "ClusterRoleBinding Name",
  SubjectKind: "Subject Kind",
  SubjectName: "Subject Name",
  SubjectNamespace: "Subject Namespace",
  SubjectAPIGroup: "Subject APIGroup",
  ServiceAccount: "ServiceAccount",
  User: "User",
  Group: "Group",
};
