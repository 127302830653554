export default {
  rules: {
    require: "必須",
    pattern: "フォーマットが不一致ですので再入力してください。",
    configMapFileName: "コンフィグのFileNameが既に存在しています。",
    location: "位置とネームスペースを選択してください",
    existed: "{0}が既に存在しています。",

    login: {
      passwordStrength: "パスワード強度",
      password: "必ず、小文字、数字を含め、6桁以上のパスワードを設定してください",
      passwordConfirm: "パスワード再確認",
      passwordDiff: "入力したパスワードが不一致です",
      email: "正しいメールアドレスを入力してください",
      userName:
        "ユーザ名称の頭文字は、必ず英文小文字にし、且つ、英数字、アンダーバー、ハイフン、および、ドッドのみを入力可能です"
    }
  },

  bind: "結び付ける",
  ResetMFA: "MFAのリセット?",
  SetMFAStepOne: "1.MFAツールを使用してQRコードをスキャンしてください",
  SetMFAStepTwo: "2.ワンタイム認証コードを入力してバインディングを完了してください",
  confirm: "確定",

  EmailSendError: "メールの送信に失敗しました。リフレッシュしてもう一度試してください",
  BindMFAError:
    "バインディングに失敗しました。リフレッシュしてもう一度試してください。複数回の試行が失敗する場合は、管理者に連絡してください",
  MFAValidateError: "MFAの検証に失敗しました。再試行してください",

  PasswordExpired: "ユーザーパスワードの有効期限が切れています",
  PasswordExpiredMessage: "ユーザーパスワードの有効期限が切れています,パスワードを更新してください",

  PasswordNearlyExpired: "パスワードの有効期限が近づいています",
  PasswordNearlyExpiredMessage: "ユーザーパスワードはあと{0}日で期限切れになりますので、早めに更新してください"
};
