export default {
  appStore: "アップストア",
  appStoreDesc: "公式のKubeStarアップストアはHelmチャートに基づいており、チャートのアップロードをサポートしています。ユーザーは自分のニーズに応じてワンクリックで迅速にデプロイすることができます",
  uploadToAppStore: "アプリケーションをアップストアにアップロードする",
  uploadTipsDesc: "KubeStarアップストアはチャートのアップロードをサポートしています。以下のアップロードボタンをクリックしてチャートファイルをアップロードしてください",
  clickToUploadTips: "クリックしてアップロード",
  searchApp: "アプリケーションを検索",
  install: "インストール",
  cluster: "クラスター",
  namespace: "ネームスペース",
  version: "バージョン",
  releaseName: "リリース名",
  addHelmParams: "パラメータを追加する",
}