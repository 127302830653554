export default {
  KubeStar: "KubeStarシステム",
  KubeStarTerminalTools: "KubeStarのたんまつどーぐ",
  history: "最近アクセス記録",
  historyEmpty: "アクセス記録がありません",
  mainNavTitle: "KubeStarのサービス",
  knowKubeStar: "KubeStarのマニュアル",
  Dashboard: "ダッシュボード",
  Event: "イベント",
  aboutUs:
    "KubeStarはエンタプライズ分散型コンテイナー管理プラットフォームであり、クラウドネイティブアプリケーションとクロスクラウドのインフラストラクチャ管理に使われる。KubeStarは環境、クラスター、リージョンとクラウド管理・クラウドネイティブアプリケーションのインフラストラクチャをまたがるツールである。",

  KubeStarControlPanel: "KubeStarコントロールパンネル",
  KubeStarDocs: "KubeStarドキュメント",

  userName: "アカウント",
  email: "Eメール",
  isAdmin: "管理者",
  password: "パスワード",
  confirmPassword: "パスワード再確認",
  personalSetting: "設定",
  changePassword: "パスワード変更",

  vendor: "プロバイダー",
  region: "リージョン",
  path: "パス",
  port: "ポート",
  hostPort: "ホストポート",
  protocol: "プロトコル",
  createTime: "生成時間",
  tag: "タブ",
  template: "テンプレート",
  detail: "詳細",
  annotations: "備考",
  resourceRelated: "関連リソース",
  host: "ドメイン",
  ingressRules: "転送ルール",

  location: "位置",
  image: "イメージ",
  cpu: "CPU",
  memory: "メモリ",
  name: "名称",
  version: "バージョン",
  currentVersion: "現在のバージョン",
  basicConf: "基本情報",
  reason: "原因",
  message: "メッセージ",
  time: "時間",
  status: "状態",
  role: "アカウント種類",
  type: "タイプ",
  address: "アクセスアドレス",
  endpoints: "インスタンスのエンドポイント",

  Audit: "監査",
  zone: "可用ゾーン",

  arch: "アーキテクチャ"
};
