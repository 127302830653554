export default {
  auditList: "審査リスト",
  auditSearchList: "検索条件",
  auditReqTime: "操作時間",
  auditCluster: "クラスタ",
  auditNamespace: "ネーミングスペース",
  auditResource: "リソース",
  auditResourceName: "リソース名",
  auditVerb: "操作タイプ",
  auditSubVerb: "操作サブタイプ",
  auditUser: "ユーザー",
  auditReqURI: "リクエストURI",
  auditPlatform: "プラットフォーム",
  auditReqObject: "リクエストオブジェクト",
  auditRspObject: "応答オブジェクト",
  auditSourceIPs: "元のIP"
};
