<template>
  <div>
    <el-dropdown @command="change" trigger="click" size="medium">
      <span class="el-dropdown-link">
        <svg-icon icon-class="enterprise" class-name="handle-icon"></svg-icon>
        <span class="organization-name">{{ organizationName }} ({{ organizationAlias }})</span>
      </span>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
            v-for="item in organizationList"
            :key="item.uuid"
            :command="item"
            icon="el-icon-office-building"
        >
          {{ item.name }}
        </el-dropdown-item>

        <el-dropdown-item divided command="join" icon="el-icon-circle-plus-outline">
          {{ $t("joinOrganization") }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dialog
        :title="$t('prompt')"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        :show-close="false"
        :close-on-press-escape="false"
        width="800px"
        custom-class="no-header-dialog"
        top="50px"
    >
      <div class="organization-dialog-header">
        <img :src="partner"/>
        <div>
          <div class="title">
            {{ $t("prompt") }}
          </div>
          <div class="desc" v-if="organizationList.length < 1">
            {{ $t("emptyOrganization") }}
            <a href="javascript:;" @click="type = 'create'">[{{ $t("createOrganization") }}]</a>/
            <a href="javascript:;" @click="type = 'join'">[{{ $t("joinOrganization") }}]</a>
          </div>

          <div class="desc">
            <span class="help">{{ $t("generateInvitationInfoTips") }}</span>
          </div>
        </div>
      </div>

      <div class="dialog-form">
        <add-organization v-if="type == 'create'" :after-submit="afterSubmit"/>
        <join-organization v-if="type == 'join'" :after-submit="afterSubmit"/>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AddOrganization from "@/pages/Organization/Add.vue";
import JoinOrganization from "@/pages/Organization/Join.vue";
import {Loading} from "element-ui";
import router, {resetRouter} from "@/router/index.js";
import partner from "@/assets/illustration/partner.svg";

export default {
  components: {AddOrganization, JoinOrganization},
  data() {
    return {
      partner,
      dialogVisible: false,
      type: "create" //join
    };
  },
  computed: {
    organizationList() {
      return this.$store.state.app.organizationList;
    },
    organizationName() {
      return this.$store.state.app.organizationName;
    },
    organizationAlias() {
      return this.$store.state.app.organizationAlias;
    }
  },
  methods: {
    afterSubmit() {
      this.dialogVisible = false;
      this.resetRouter();
    },
    change(item) {
      if (item === "join") {
        this.dialogVisible = true;
        this.type = "join";
      } else {
        const loading = Loading.service({
          lock: true,
          text: "Loading...",
          spinner: "el-icon-loading",
          fullscreen: true
        });

        setTimeout(async () => {
          this.$store.commit("app/SET_ORGANIZATION", item);
          this.$store.commit("app/CLEAR_RECORDLIST");
          await this.resetRouter();
          loading.close();
        }, 300);
      }
    },

    async resetRouter() {

      resetRouter();

      //await this.$store.dispatch("app/getOrganizationList");

      let {role} = await this.$store.dispatch("app/getUserInfo");

      const accessRoutes = await this.$store.dispatch("permission/generateRoutes", role);
      accessRoutes.forEach(item => {
        router.addRoute(item);
      });

      const {fullPath} = this.$route;

      // 重新获取列表
      this.$nextTick(() => {
        this.$router.replace({
          path: "/redirect" + fullPath
        });
      });
    }
  },

  mounted() {
    if (this.organizationList.length < 1) {
      this.dialogVisible = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.organization-name {
  max-width: 300px;
  @include text-overflow();
  margin-left: 4px;
}

.organization-dialog-header {
  height: 150px;
  margin-bottom: 20px;
  @include flex(flex-start);

  img {
    width: 180px;
    margin-right: 40px;
  }

  .title {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .desc {
    text-indent: 12px;
    position: relative;
    line-height: 30px;

    &::before {
      content: "";
      position: absolute;
      left: 4px;
      top: 50%;
      margin-top: -2px;
      width: 4px;
      height: 4px;
      background-color: $color-sub;
    }
  }
}

.dialog-form {
  .add-form {
    width: 96%;
  }
}
</style>
