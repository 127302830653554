import http from "../http";

export const register = data => {
  return http({
    method: "post",
    data,
    url: `/auth.kubestar.io/register`
  });
};

export const login = (data, headers) => {
  return http({
    method: "post",
    data,
    url: `/auth.kubestar.io/login`,
    headers
  });
};

export const logout = data => {
  return http({
    method: "post",
    data,
    url: `/auth.kubestar.io/logout`
  });
};

export const userList = (id, params) => {
  return http({
    method: "get",
    params,
    url: `/iam.kubestar.io/organizations/${id}/users`
  });
};

export const userAdd = data => {
  return http({
    method: "post",
    data,
    url: `/iam.kubestar.io/users`
  });
};

export const deleteUser = (organization, user) => {
  return http({
    method: "delete",
    url: `/iam.kubestar.io/organizations/${organization}/users/${user}`
  });
};

export const getUserInfo = id => {
  return http({
    method: "get",
    url: `/iam.kubestar.io/users/${id}`
  });
};

export const getRoleList = () => {
  return http({
    method: "get",
    url: `/manage.kubestar.io/roles`
  });
};

export const roleBind = data => {
  return http({
    method: "post",
    data,
    url: `/manage.kubestar.io/rolebindings`
  });
};

export const userUpdate = data => {
  return http({
    method: "put",
    data,
    url: `/iam.kubestar.io/users`
  });
};

export const userPasswordUpdate = data => {
  return http({
    method: "patch",
    data,
    url: `/iam.kubestar.io/users/${data.userName}`
  });
};

export const bindMFA = data => {
  return http({
    method: "put",
    data,
    url: `/iam.kubestar.io/users/${data.userName}/mfa`
  });
};

export const deleteMFA = name => {
  return http({
    method: "delete",
    url: `/iam.kubestar.io/users/${name}/mfa`
  });
};

export const verifyUser = params => {
  return http({
    method: "get",
    url: `/iam.kubestar.io/users/verify`,
    params
  });
};

export const sendResetEmail = params => {
  return http({
    method: "get",
    url: `/iam.kubestar.io/reset/email`,
    params
  });
};

export const resetPassword = data => {
  return http({
    method: "post",
    url: `/iam.kubestar.io/users/reset`,
    data
  });
};

export const CheckUserMFAStatus = id => {
  return http({
    method: "get",
    url: `/iam.kubestar.io/users/mfaStatus/${id}`
  });
};

export const GenerateMFAQR = id => {
  return http({
    method: "get",
    url: `/iam.kubestar.io/users/GenerateMFAQR/${id}`
  });
};

export const ValidateBindMFA = (data, id) => {
  return http({
    method: "post",
    url: `/iam.kubestar.io/users/ValidateBindMFA/${id}`,
    data
  });
};

export const ValidateMFA = (data, id) => {
  return http({
    method: "post",
    url: `/iam.kubestar.io/users/ValidateMFA/${id}`,
    data
  });
};

export const ResetMFA = id => {
  return http({
    method: "get",
    url: `/iam.kubestar.io/users/RequestResetMFA/${id}`
  });
};

export const ValidateResetMFA = (data, id) => {
  return http({
    method: "post",
    url: `/iam.kubestar.io/users/ValidateResetMFA/${id}`,
    data
  });
};
