export default {
  Ingress: "Ingress",
  IngressClass: "IngressClass",
  ingressName: "Ingress名称",
  useHTTPS: "是否启用HTTPS",
  ingressSubHost: "子路径",
  ingressRules: "转发规则",
  ingressHint:
    "提示: 查看拓扑图上Service和Pod的详情，请右键点击选择具体的卡片，点击“查看资源详情”，即可跳转到对应资源详情页面",
  viewResourceDetail: "查看资源详情",
  copyResourceName: "复制资源名称",
  copyResourceNameSuccess: "复制成功",

  ingressClassController: "IngressClass Controller",
  parametersAPIGroup: "Parameters.apiGroup",
  parametersKind: "Parameters.Kind",
  parametersName: "Parameters.Name",
  parametersNamespace: "Parameters.Namespace",
  parametersScope: "Parameters.Scope"
};
