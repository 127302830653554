export default {
  Ingress: "イングレス",
  ingressName: "イングレス名称",
  useHTTPS: "HTTPS使用",
  ingressSubHost: "サブパス",
  ingressRules: "転送ルール",

  ingressClassController: "IngressClass Controller",
  parametersAPIGroup: "Parameters.apiGroup",
  parametersKind: "Parameters.Kind",
  parametersName: "Parameters.Name",
  parametersNamespace: "Parameters.Namespace",
  parametersScope: "Parameters.Scope"
};
