export default {
  auditList: "审计列表",
  auditSearchList: "搜索条件",
  auditReqTime: "操作时间",
  auditCluster: "集群",
  auditNamespace: "命名空间",
  auditResource: "请求资源",
  auditResourceName: "资源名称",
  auditVerb: "请求类型",
  auditSubVerb: "请求子类型",
  auditUser: "用户",
  auditReqURI: "请求地址",
  auditPlatform: "操作平台",
  auditBeforeReqObject: "请求前对象",
  auditReqObject: "请求对象",
  auditRspObject: "响应对象",
  auditSourceIPs: "原始IP"
};
