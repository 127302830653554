import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import app from "./modules/app.js";
import permission from "./modules/permission.js";

export default new Vuex.Store({
  modules: {
    app,
    permission,
  }
});
