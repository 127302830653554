export default {
    Cluster: "クラスタ",
    Node: "ノード",
    nodeName: "ノード名称",
    nodeList: "ノードリスト",
    clusterKind: "プロバイダー",
    clusterResource: "クラスタリソース状況",
    nodeResource: "ノードリソース状況",
    hotDeployment: "ホットアプリケーション",
    kubernetes: "Kubernetes",
    monitor: "モニタ",
    security: "セキュリティ",
    storage: "ストレージ",
    namespace: "ネームスペース",
    install: "インストール",
    createTime: "稼働時間",
    clusterTopoTarget: {
        cpuUsagePercent: "CPU使用率",
        memUsagePercent: "メモリ使用率",
        diskUsagePercent: "ハードディスク使用率",
        networkReceivePercent: "ネットワーク受信量",
        networkTransmitPercent: "ネットワーク送信量",
        cpuUsageTotal: "CPU使用量(core)",
        memWorkingBytes: "メモリ使用量(GiB)"
    },
    JSONSecret: "JSONセキュリティキー",
    kubeletVersion: "kubeletバージョン",
    osImage: "操作システムミラーリング",
    kernelVersion: "カーネルバージョン",
    kubeProxyVersion: "kubeProxyバージョン",
    containerRuntimeVersion: "コンテナランタイムバージョン",
    architecture: "コンピューティングアーキテクチャ",

    taints: "汚点管理",
    taintsDesc: "汚点：反対に taint はNodeがある種のPodを排除できるようにします",
    taintsNoSchedule: "NoSchedule(スケジューリング禁止、スケジューリングされたリソースは影響を受けない)",
    taintsPreferNoSchedule: "PreferNoSchedule(スケジュール化を推奨しない)",
    taintsNoExecute: "NoExecute(スケジューリング禁止)",

    tolerations: "Tolerations",
    tolerationsDesc:
        "toleration 一致するtaintが付与されたNodeへPodがスケジューリングされることを認めるものです。ただしそのNodeへ必ずスケジューリングされるとは限りません。",
    
    involvedObjectKind: "イベントの関与オブジェクトの種類",
    involvedObjectNamespace: "イベントの関与オブジェクトのネームスペース",
    involvedObjectName: "イベントの関与オブジェクトの名前",
    eventComponent: "イベントのコンポーネント",
    eventReason: "イベントの理由",
    eventType: "イベントのタイプ",
    eventHost: "イベントのホスト",
    clusterEvent: "クラスターイベント"
};
