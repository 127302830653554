export default {
  prompt: "説明",
  delete: "{0}を削除してもよろしいですか？",
  restart: "{0}を再起動してもよろしいですか？",
  pause: "{0}を停止してもよろしいですか？",
  resume: "{0}を再開してもよろしいですか？",
  drain: "ノード {0} をドレインしてもよろしいですか？",
  confirm: "確認するため、名称を入力してください。",
  collect: "{0}をお気に入りに追加してもよろしいですか？",
  unschedulable: "{0}のスケジューリングが停止してもよろしいですか？",
  recover: "{0}のスケジューリングが再開してもよろしいですか？",
  cancelCollect: "{0}をお気に入りから削除してもよろしいですか？",
  tokenError: "Tokenの期限が切れましたので、再ログインしてください。",
  providerTips: "該当ユーザが操作できるクラスターをまだ設定されていないため、操作不可。",
  batchDelete: "以下のリソースを削除してもよろしいですか？",
  snapshotRollback: "スナップショット{0}にロールバックしてもよろしいですか？"
};
