export default {
  Namespace: "Namespaces",
  namespaceName: "Namespace name",
  resourceQuota: "Resource quota",
  limitRange: "Limit range",

  quota: "Quota",
  cpuQuotaLimits: "CPU total resource limitation",
  cpuQuotaLimitsTips: "CPU upper limit is available for current namespace",
  memQuotaLimits: "Total memory limitation",
  memQuotaLimitsTips: "Memory upper limit is available for  current namespace",
  resourceLimits: "Resource limitation",
  podResourceLimitsTips:
    "The upper limits of quota is equal to that of all resource in pod, the maximum resource number consumed by the entire pod as well. It is not available if the defined resource is more than quota upper limits or less than quota lower limits.",
  containerResourceLimitsTips:
    "For container, the quota upper limits and lower limits is similar with pod, besides just only for single container",

  cpuQuotaUpperLimit: "Quota upper limits of CPU ",
  cpuQuotaLowerLimit: "Lower upper limits of CPU ",
  cpuQuotaDefault: "Default quota of CPU",

  memQuotaUpperLimit: "Quota upper limits of Memory",
  memQuotaLowerLimit: "Lower upper limits of Memory",
  memQuotaDefault: "Default quota of Memory",

  allNamespacesTips: "All namespaces",
  whitelistNamespacesTips: "Allowed namespaces",

  resourceTopology: {
    hierarchicalLayout: "Hierarchical Layout",
    compoundLayout: "Hierarchical Layout"
  }
};
