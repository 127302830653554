export default {
  costCloudFactoryProportionYearly: "今年のクラウドベンダーの消費比率",
  costCloudFactoryProportionSeason: "今四半期のクラウドベンダーの消費比率",
  costCloudFactoryProportionMonthly: "今月のクラウドベンダーの消費比率",

  costCloudAccountProportionYearly: "今年のクラウドアカウントの消費比率",
  costCloudAccountProportionSeason: "今四半期のクラウドアカウントの消費比率",
  costCloudAccountProportionMonthly: "今月のクラウドアカウントの消費比率",

  costCloudServiceProportionYearly: "今年のクラウドサービスの消費比率",
  costCloudServiceProportionSeason: "今四半期のクラウドサービスの消費比率",
  costCloudServiceProportionMonthly: "今月のクラウドサービスの消費比率",

  costTrendYearly: "年間消費傾向",
  costTrendSeason: "四半期の消費傾向",
  costTrendMonthly: "月次の消費傾向",

  yearly: "ねんど",
  season: "きど",
  monthly: "げっど",

  costCloudServiceUsageDetailYearly: "今年のクラウドサービス利用詳細",
  costCloudServiceUsageDetailSeason: "今四半期のクラウドサービス利用詳細",
  costCloudServiceUsageDetailMonthly: "今月のクラウドサービス利用詳細",

  ranking: "順位",
  serviceName: "サービス名",
  cloudAccount: "クラウドアカウント",
  cost: "料金",

  costTotalYearly: "今年の総消費額",
  costTotalSeason: "今四半期の総消費額",
  costTotalMonthly: "今月の総消費額",

  costAnalyses: {
    costDetails: "コスト詳細",
    currentCost: "現在のコスト",
    predictedCost: "予測コスト",
    costTrends: "コストトレンド",
    cloudServiceCostsTop5: "トップ5のクラウドサービスのコスト",
    cloudServiceGrowthRatesTop5: "トップ5のクラウドサービスの成長率",

    tableCloudAccount: "クラウドアカウント",
    tableCloudName: "サービス名",
    tableCloudCost: "現在のコスト",
    tableCloudPredictedCosts: "予測コスト",

    tableIncreasedPercentAccount: "クラウドアカウント",
    tableIncreasedPercentService: "サービス名",
    tableIncreasedPercentIncreasePercent: "成長率",
    tableIncreasedPercentcostCurrent: "現在のコスト",

    dns: "DNS",
    loadBalance: "アプリケーション負荷分散",
    eks: "EKS",
    gke: "GKE",
    crossCloudService: "クロスクラウドサービス",
    backEndService: "バックエンドサービス",
    exportGateway: "エクスポートゲートウェイ"
  }
};
