export default {
  clusterMonitorDetail: "Cluster Monitor Config",
  monitor: "Monitor",
  monitorType: "Monitor type",
  monitorReady: "Enable Monitor Config",
  monitorHost: "Monitor server address",
  clusterMonitor: "Cluster monitoring",
  namespaceMonitor: "Namespace monitor",
  currentMonthCost: "Cost of current month",
  previousMonthCost: "Previous month",
  masterComponentsStatus: "Master components status",
  nodeStatus: "Node components status",
  nodeAbstract: "Node abstract",
  kubernetesSummary: "Kubernetes workloads summary",
  namespaceRank: "Namespace usage rank",
  deploymentRank: "Deployment usage rank",
  statefulSetRank: "StatefulSet usage rank",
  cronJobRank: "CronJob usage rank",
  daemonSetRank: "DaemonSet usage rank",
  rankByCPU: "Order by CPU",
  rankByMemory: "Order by memory",
  costDashboard: "Cost center",

  podAlias: "Pod Alias",
  nodeAlias: "Node Alias",
  kubeletAlias: "Kubelet Alias",
  podAliasDesc:
    "Different versions of Prometheus or exporters collect different metadata labels. Generally speaking, 'pod' and 'pod_name' are metadata labels used in the Kubernetes environment, with 'pod' being the default.",
  nodeAliasDesc:
    "Different versions of Prometheus or exporters collect different metadata labels. Generally speaking, 'node' and 'node_name' are metadata labels used in the Kubernetes environment, with 'node' being the default.",
  kubeletAliasDesc:
    "Different versions of Prometheus or exporters collect different metadata labels. Generally speaking, 'cadvisor' and 'kubelet' are metadata labels used in the Kubernetes environment, with 'kubelet' being the default.",

  tenantConfig: "Multi Tenant Config",
  thanosConfig: "Thanos Config",
  thanosAdditionLabel:
    "When using Thanos or Prometheus to collect monitoring data from multiple clusters, to differentiate between different clusters, additional unique metadata label Key/Value are often used.",
  uniqueLabelKey: "Unique Label Key",
  uniqueLabelValue: "Unique Label Value",
  thanosDesc:
    "In a multi-tenant environment, using the Thanos Receiver architecture requires specifying tenant information for monitoring data. The data comes from the receiver component's default-tenant-id or tenant-label-name",
  thanosLabelDesc: "Under the Thanos architecture, the unique identifier label for Prometheus",

  costDashboardDesc: "Cost analyze",
  clusterMonitorDesc: "Cluster monitoring in multi-demensions",

  timeRange: "Time range",
  customTimeRange: "Customize time range",
  startTime: "Start time",
  endTime: "End time",
  clusterMonitorConf: "Cluster monitoring configuration",
  clusterMonitorConfDescription: "Cluster information of monitoring source/address/on-off,etc",

  customMonitor: "Customize monitoring",
  customMonitorPanel: "Customize monitoring panel",
  createDashboard: "Create dashboard",
  dashboardName: "Dashboard Name",
  dashboardCategory: "Dashboard category",
  createCategory: "Create category",
  categoryName: "Category name",
  allCategory: "All category ",

  selectPrometheusOrigin: "Select prometheus cluster",
  addClusterMonitor: "Add cluster monitoring",
  editPanel: "Edit panel",
  addQuery: "Add query",
  panelSetting: "Panel setting",
  panelTitleSetting: "Title setting",
  panelTitle: "Panel title",
  panelDescription: "Panel description",
  panelVisualization: "Visualization",
  panelDisplay: "Display setting",
  openArea: "Display area",
  panelThreshold: "Threshold setting",
  threshold: "Threshold",
  panelDangerValue: "Danger value",

  panelQuery: "Query",
  promQLQuickGenerate: "Generate PromQL quickly",
  legendFormat: "Legend",
  legendFormatTooltipText: "{{}} is be used to display legend, ex.{{pod}} display Pod name",

  promQLGenerator: "PromQL generator",
  promQLSelectMetric: "Select metrics",
  promQLAggrFn: "Aggregate function",
  instantValue: "Instant",
  intervalValue: "Interval",
  aggrFn: {
    sum: "Summusize",
    count: "Elements number",
    rate: "Average increase rate",
    irate: "Increase rate",
    increase: "Increase",
    min: "Minimum value",
    max: "Maximum value",
    avg: "Average value",
    topk: "Top k elements",
    bottomk: "Bottom k elements"
  },
  promQLAggrBy: "Aggregate dimension",
  promQLAggrTips1:
    "'by' is used to keep the listed tags from the calculation results and remove other tags. 'without' is the opposite,only the listed tags are removed from the result vector",
  promQLAggrTips2: "e.g: sum(http_requests_total) by (code,handler) keeps code, handler in data",

  promQLTimerange: "Interval sampling value",
  promQLKValue: "K value",
  promQLLabelFilter: "Lable filter condition",
  promQLAddLabel: "Add label",
  promQLLabelTips1: "Label indicates dimension of current sample,  Prometheus uses label for filtering",
  promQLLabelTips2: "e.g：api_http_requests_total{method='POST', handler='/messages'}",

  promQLExpression: {
    equal: "Totally equal",
    notEqual: "Not equal",
    regular: "Regular expression"
  }
};
