export default {
  costCloudFactoryProportionYearly: "本年度云厂商消费占比",
  costCloudFactoryProportionSeason: "本季度云厂商消费占比",
  costCloudFactoryProportionMonthly: "本月度云厂商消费占比",

  costCloudAccountProportionYearly: "本年度云账号消费占比",
  costCloudAccountProportionSeason: "本季度云账号消费占比",
  costCloudAccountProportionMonthly: "本月度云账号消费占比",

  costCloudServiceProportionYearly: "本年度云服务消费占比",
  costCloudServiceProportionSeason: "本季度云服务消费占比",
  costCloudServiceProportionMonthly: "本月度云服务消费占比",

  costTrendYearly: "年度消费趋势",
  costTrendSeason: "季度消费趋势",
  costTrendMonthly: "月度消费趋势",

  yearly: "年度",
  season: "季度",
  monthly: "月度",

  costCloudServiceUsageDetailYearly: "本年度云服务用量明细",
  costCloudServiceUsageDetailSeason: "本季度云服务用量明细",
  costCloudServiceUsageDetailMonthly: "本年度云服务用量明细",

  ranking: "排名",
  serviceName: "服务名称",
  cloudAccount: "云账号",
  cost: "费用",

  costTotalYearly: "本年度消费总计",
  costTotalSeason: "本季度消费总计",
  costTotalMonthly: "本月度消费总计",

  costAnalyses: {
    costDetails: "成本详情",
    currentCost: "当前成本",
    predictedCost: "预测成本",
    costTrends: "成本趋势",
    cloudServiceCostsTop5: "云服务成本TOP5",
    cloudServiceGrowthRatesTop5: "云服务增长率TOP5",

    tableCloudAccount: "云账号",
    tableCloudName: "服务名称",
    tableCloudCost: "当前成本",
    tableCloudPredictedCosts: "预测成本",

    tableIncreasedPercentAccount: "云账号",
    tableIncreasedPercentService: "服务名称",
    tableIncreasedPercentIncreasePercent: "增长率",
    tableIncreasedPercentcostCurrent: "当前成本",

    dns: "DNS",
    loadBalance: "应用负载均衡",
    eks: "EKS",
    gke: "GKE",
    crossCloudService: "跨云服务",
    backEndService: "后端服务",
    exportGateway: "出口网关"
  }
};
