export default {
  rules: {
    require: "Required",
    pattern: "Incorrect format，please re-enter",
    configMapFileName: "No matter same configuration or not, FileName is not allowed to repeat",
    location: "Please choose location and namespace",
    existed: "{0} already existed，please change",

    login: {
      passwordStrength: "Password strength",
      password:
        "At least 8 digits in length as well as number、uppercase and lowercase letters and special characters are required",
      passwordConfirm: "Please confirm password",
      passwordDiff: "Unmatched password",
      email: "Please enter correct email",
      userName:
        "The first letter of user name is lowercase, and just only alphanumeric characters, _, - and .are available"
    }
  },

  bind: "bind",
  ResetMFA: "Reset MFA?",
  SetMFAStepOne: "1. Scan the QR code using an MFA tool.",
  SetMFAStepTwo: "2. Enter the one-time verification code to complete the binding.",
  confirm: "Confirm",

  EmailSendError: "Failed to send email. Please refresh and try again.",
  BindMFAError:
    "Binding failed. Please refresh and try again. If multiple attempts fail, please contact the administrator.",
  MFAValidateError: "MFA verification failed. Please retry.",

  PasswordExpired: "User password has expired",
  PasswordExpiredMessage: "User password has expired, Please update your password.",

  PasswordNearlyExpired: "Password is about to expire",
  PasswordNearlyExpiredMessage: "User password will expire in {0} days. Please update it promptly"
};
