export default {
  rules: {
    require: "必填信息",
    pattern: "格式不正确，请重新输入。",
    configMapFileName: "同一文件不可以挂载不同的配置项",
    location: "请选择发布位置和命名空间",
    existed: "{0}名称已存在，请更换。",

    login: {
      passwordStrength: "密码强度",
      password: "密码必须包含数字、大小写字母和特殊字符，长度至少为 8 位",
      passwordConfirm: "请再次输入密码",
      passwordDiff: "两次输入密码不一致",
      email: "请输入正确的邮箱地址",
      userName: "用户名的首字母必须为英文小写，并且只能输入字母数字字符，下划线，连字符和点。"
    }
  },

  bind: "绑定",
  ResetMFA: "重置MFA?",
  SetMFAStepOne: "1.使用MFA工具扫描二维码",
  SetMFAStepTwo: "2.输入一次性验证码完成绑定",
  confirm: "确定",

  EmailSendError: "发送Email失败，请刷新后重试",
  BindMFAError: "绑定失败，请刷新后重试，多次重试失败请联系管理员",
  MFAValidateError: "MFA验证失败，请重试",

  PasswordExpired: "密码过期",
  PasswordExpiredMessage: "用户密码已过期，请更新密码",

  PasswordNearlyExpired: "密码即将过期",
  PasswordNearlyExpiredMessage: "用户密码还有{0}天过期，请及时更新"
};
