<template>
  <header class="header clearfix">
    <div class="header-nav-container">
      <el-popover
          placement="bottom"
          width="1000"
          trigger="click"
          class="header-nav"
          popper-class="header-nav-popover"
          :visible-arrow="false"
          v-model="popoverVisible"
      >
        <user-nav/>

        <div slot="reference" class="menu-icon-container">
          <svg-icon icon-class="menu" class-name="handle-icon"></svg-icon>
        </div>
      </el-popover>
    </div>

    <div class="logo">
      <img :src="logo"/>
      <a href="#/">
        {{ $t("KubeStar") }}
      </a>
    </div>

    <div class="right-menu">

      <organization-list class="right-menu-item"/>

      <language-select class="right-menu-item"/>

      <div class="right-menu-item">

        <el-dropdown trigger="click" size="medium">
          <span class="el-dropdown-link">
            <img src="~@/assets/avatar.svg" class="header-right-menu-item-icon"/>
            <span>{{ userName }}</span>
          </span>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-setting">
              <span>
                <user-setting :userName="userName" usage="setting"/>
              </span>
            </el-dropdown-item>

            <el-dropdown-item icon="el-icon-info">
              <about-us/>
            </el-dropdown-item>

            <el-dropdown-item icon="el-icon-switch-button" @click.native="logout" divided>
              <span>{{ $t("logout") }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

    </div>
  </header>
</template>

<script>
import AboutUs from "./AboutUs";
import logo from "@/assets/logo.svg";
import UserSetting from "@/pages/User/Update";
import UserNav from "./UserNav";
import OrganizationList from "./OrganizationList";
// import AlarmGlobal from "@/components/AlarmGlobal";
import {Loading} from "element-ui";
import {logout} from "api/user";

export default {
  components: {
    UserSetting,
    // AlarmGlobal,
    AboutUs,
    UserNav,
    OrganizationList
  },

  data() {
    return {
      logo,
      popoverVisible: false
    };
  },

  methods: {
    logout() {
      const loading = Loading.service({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        fullscreen: true
      });

      logout({}).then(() => {
        this.$store.dispatch("app/logout").then(() => {
          this.$router.push({path: "/login"});
          loading.close();
        });
      });
    }
  },

  computed: {
    userName() {
      return this.$store.state.app.userName;
    }
  },

  watch: {
    $route() {
      this.popoverVisible = false;
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

$header-hover-color: #000c17;

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;
  line-height: 50px;
  padding: 0 40px;
  background-color: $header-bg-color;
  z-index: 999;
// border-bottom: 1px solid #545b64; overflow: hidden; z-index: 1999; text-align: center;
}

.header-nav-container {
  float: left;
  line-height: 50px;
}

.menu-icon-container {
  height: 50px;
  @include flex(flex-start);

  .svg-icon {
    margin: 0;
  }
}

.right-menu {
  float: right;
  line-height: 50px;
  height: 100%;
}

.right-menu-item {
  float: left;
  height: 100%;
  cursor: pointer;
  color: $color-sub;
  transition: 0.3s all ease;
  vertical-align: middle;
  font-size: 12px;
  padding: 0 10px;

  .el-dropdown {
    height: 100%;
  }

  .el-dropdown-link {
    @include flex(flex-start);
    height: 100%;
    color: $color-sub;
    transition: 0.2s all ease;
    color: #fff;
    font-size: 14px;
  }

  ::v-deep.el-badge {
    vertical-align: text-bottom;
  }

  .header-right-menu-item-icon {
    margin-right: 4px;
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
}

.logo {
  height: 50px;
  @include flex();
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  a {
    color: #fff;
    font-size: 16px;
  }

  img {
    height: 22px;
    width: 22px;
    margin-right: 4px;
  }
}

.header-nav {
  @extend .right-menu-item;
}

.header-nav-popover {
  max-height: calc(100vh - 40px);
  overflow-y: scroll;
  margin-bottom: 20px;

  &.el-popover {
    background: $header-bg-color;
    border-color: transparent;
    border-top-color: #545b64;
    padding: 0;
  }

  &.el-popper[x-placement^="bottom"] {
    margin-top: 0;
  }
}

.quick-list {
  @include resource-card();
}
</style>
