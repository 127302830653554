<template>
  <div>
    <el-form :model="form" size="small" ref="form">
      <div v-for="(item, index) in form.labels" :key="index">
        <div v-if="item.key != 'kubectl.kubernetes.io/last-applied-configuration'">
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item
                :prop="'labels.' + index + '.key'"
                :rules="[
                  { required: true, message: $t('rules.require'), trigger: 'change' },
                  {
                    pattern: keyPattern,
                    message: '必填项，允许小写字母/数字/短横线组成（ProviderGCP允许大写字母）',
                    trigger: 'change'
                  }
                ]"
              >
                <el-autocomplete
                  v-model="item.key"
                  v-if="selectable"
                  :fetch-suggestions="querySearch"
                  @select="
                    value => {
                      autocompleteSelect(value, item);
                    }
                  "
                  style="width: 100%"
                >
                  <template #prepend>
                    Key
                  </template>

                  <template slot-scope="{ item }">
                    <span>{{ item }}</span>
                  </template>
                </el-autocomplete>

                <el-input v-model="item.key" size="small" v-else>
                  <template #prepend>
                    Key
                  </template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="11">
              <el-form-item
                :prop="'labels.' + index + '.value'"
                :rules="[{ required: true, message: $t('rules.require'), trigger: 'change' }]"
              >
                <el-input v-model="item.value" size="small">
                  <template #prepend>
                    Value
                  </template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="2">
              <el-button icon="el-icon-delete" circle @click="form.labels.splice(index, 1)" size="small"></el-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>

    <el-button size="small" icon="plus" @click="form.labels.push({ key: '', value: '' })" style="margin-right: 10px;">
      + {{ $t("handle.add") }}
    </el-button>

    <el-dropdown v-if="editType === 'templateAnnotations'">
      <el-button type="primary" size="small" icon="plus">
        + {{ $t("handle.addMetricTemplate") }} <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="addMetricTemplateBlack">
          {{ $t("handle.addMetricTemplateBlack") }}
        </el-dropdown-item>
        <el-dropdown-item @click.native="addMetricTemplateWhite">
          {{ $t("handle.addMetricTemplateWhite") }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { cloneDeep, transform } from "lodash";
import { getClusterConf } from "api/cluster";
export default {
  name: "EditLabels",

  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    dialogVisible: {
      type: Boolean
    },
    editType: String,
    locationList: Array,
    selectable: Boolean,
    keyPattern: {
      type: RegExp
    }
  },

  data() {
    return {
      form: {
        labels: []
      },

      configList: [],
      configData: {}

      // rules: {
      //   checkKeys: [
      //     { required: true, message: this.$t("rules.require"), trigger: "change" },
      //     {
      //       pattern: this.keyPattern,
      //       message: this.$t("tips.nameCommonPattern"),
      //       trigger: "change"
      //     }
      //   ]
      // }
    };
  },

  watch: {
    locationList: {
      handler: function() {
        if (this.selectable && this.locationList.length > 0) this.getConfigList();
      },

      deep: true
    }
  },

  methods: {
    getConfigList() {
      let provider;

      for (let item of this.providers) {
        if (
          item.cluster == this.locationList[2] &&
          item.kind == this.locationList[0] &&
          item.regionID == this.locationList[1]
        ) {
          provider = item.provideruuid;
          break;
        }
      }

      if (provider) {
        getClusterConf(provider).then(response => {
          if (response.code == 0) {
            // eslint-disable-next-line no-prototype-builtins
            if (response.data.hasOwnProperty("variables")) {
              this.configData = response.data.variables;
              this.configList = Object.keys(response.data.variables);
            }
          }
        });
      }
    },

    autocompleteSelect(value, item) {
      item.key = value;
      item.value = this.configData[value];
    },

    querySearch(queryString, cb) {
      var results = [];
      const configList = ["author", "connector", "usage"];
      if (this.editType === "labelEdit") {
        results = queryString
          ? configList.filter(item => {
              return item.includes(queryString);
            })
          : configList;
      } else {
        results = queryString
          ? this.configList.filter(item => {
              return item.includes(queryString);
            })
          : this.configList;
      }
      cb(results);
    },

    addMetricTemplateBlack() {
      this.form.labels.push(
        ...[
          { key: "prometheus.io/http-probe", value: "true" },
          { key: "prometheus.io/http-probe-port", value: "8080" },
          { key: "prometheus.io/http-probe-path", value: "/probe" }
        ]
      );
    },

    addMetricTemplateWhite() {
      this.form.labels.push(
        ...[
          { key: "prometheus.io/http-metric", value: "true" },
          { key: "prometheus.io/http-metric-scheme", value: "http" },
          { key: "prometheus.io/http-metric-port", value: "8080" },
          { key: "prometheus.io/http-metric-path", value: "/metrics" }
        ]
      );
    }
  },

  mounted() {
    this.form.labels = transform(
      cloneDeep(this.data),
      function(result, value, key) {
        result.push({ key, value });
      },
      []
    );

    if (this.selectable && this.locationList?.length > 0) this.getConfigList();
  },

  computed: {
    providers() {
      return this.$store.state.app.userInfo.providers;
    }
  }
};
</script>
