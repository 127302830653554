export default {
  ConfigMap: "Configmaps",
  configMapName: "Configmap name",
  configMapRootPath: "Configmap root path",
  mountPath: "Mount path",
  configMapItem: "Configmap item",
  mountContainer: "Mount container: ",
  mountFile: "Mount file",
  fileContent: "File content",
  overlay: "Overlay",
  mode: "ReadWrite mode",

  configmapType: "Data type",
  configmapTextDataType: "Text type",
  configmapBinaryDataType: "Binary type",
  configmapTypeAffect:
    "The type affects the encoding method of the configuration items during submission and whether the configuration items can be edited",
  configmapTypeUse:
    "The text type is suitable for plain text and other common configuration files, while the binary type is suitable for certificates and other binary files",
  configmapTextDesc:
    "The value of the configuration item can be manually modified and will not be converted to base64 when submitted",
  configmapTextDesc2: "Text type, suitable for ordinary configuration files such as text files.",
  configmapBinaryDesc:
    "The value of the configuration item is not allowed to be manually modified, and it will be converted to base64 when submitted",
  configmapBinaryDesc2: "Binary type (stored as Base64), suitable for binary files such as certificates.",
  configmapFileSizeLimit: "The file size cannot exceed 1MB",
  fileAlreadyExist: "file already exists, please check and resubmit",
  currentDataType: "Current configmap data type is:",
  configmapUpdateSuccess:
    "Configmap modified successfully. Please restart the corresponding Pod for the changes to take effect."
};
