export default {
  loginBtn: "登录",
  registerBtn: "注册",
  enterUserName: "请输入用户名",
  enterPassword: "请输入密码",
  enterEmail: "请输入电子邮箱",
  passwordConfirm: "请再次输入密码",
  registerLink: "注册账户",
  loginLink: "已有账户？去登录",
  logout: "退出",
  reLogin: "重新登录",

  toLogin: "立即登录",
  toRegister: "前往注册",

  forgetPassword: "忘记密码?",
  resetPassword: "重置密码",
  inputUserNameToResetPassword: "请输入要找回密码的用户名",
  inputUserRegisterEmail: "请输入用户注册时填写的邮箱",
  sendEmail: "发送邮件",
  nextStep: "下一步",
  gotoLogin: "前往登录",
  resetPasswdEmailSend: "重置密码的邮件已发送至 ",
  captchaCode: "验证码",
  swipeToRight: "向右滑动",

  usedToResetPassword: "用于找回密码",

  hello: "你好",
  pleaseInputPassword: "请填写以下密码信息",

  enterValidateCode: "请输入验证码"
};
