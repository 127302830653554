export default {
  loginBtn: "Login",
  registerBtn: "Register",
  enterUserName: "Please enter username",
  enterPassword: "Please enter password",
  enterEmail: "Please enter email",
  passwordConfirm: "Please confirm password",
  registerLink: "Register",
  loginLink: "Login",
  logout: "Logout",
  reLogin: "Re-Login",

  toLogin: "Login",
  toRegister: "Register",

  forgetPassword: "Forget password?",
  resetPassword: "Reset password",
  inputUserNameToResetPassword: "Please input the username to find password",
  inputUserRegisterEmail: "Please input the email to find password",
  sendEmail: "Send email",
  nextStep: "Next",
  gotoLogin: "Go to login",
  resetPasswdEmailSend: "The email to reset password has been send to ",
  captchaCode: "Captcha",
  swipeToRight: "Swipe to right",

  usedToResetPassword: "Used to reset account password",

  hello: "hello",
  pleaseInputPassword: "please fill in the following password information",

  enterValidateCode: "Please enter the verification code"
};
