export default {
  Namespace: "命名空间",
  namespaceName: "命名空间名称",
  resourceQuota: "资源配额",
  limitRange: "资源上限/下限",

  quota: "配额",
  cpuQuotaLimits: "CPU总资源限制",
  cpuQuotaLimitsTips: "当前命名空间可以使用的最大 CPU 上限",
  memQuotaLimits: "内存总资源限制",
  memQuotaLimitsTips: "当前命名空间可以使用的最大 内存 上限",
  resourceLimits: "资源限制",
  podResourceLimitsTips:
    "配额上限表示 Pod 中所有容器资源的上限，也就是整个 Pod 消耗的最大资源数量，如果 Pod 定义中的资源值超出配额上限，或低于配额下限，则无法成功创建",
  containerResourceLimitsTips:
    "在 Container 部分，配额上限和配额下限的含义和 Pod 中类似，只不过是针对单个的 Container 而言",

  cpuQuotaUpperLimit: "CPU配额上限",
  cpuQuotaLowerLimit: "CPU配额下限",
  cpuQuotaDefault: "CPU默认配额",

  memQuotaUpperLimit: "内存配额上限",
  memQuotaLowerLimit: "内存配额下限",
  memQuotaDefault: "内存默认配额",

  allNamespacesTips: "全部命名空间",
  whitelistNamespacesTips: "已授权命名空间",

  resourceTopology: {
    hierarchicalLayout: "层次布局",
    compoundLayout: "复合布局"
  }
};
