<template>
  <div>
    <div class="kubestar-title">
      <svg-icon icon-class="control"></svg-icon>
      {{ $t("KubeStarControlPanel") }}
    </div>

    <div class="search-container">
      <div class="search-title">
        <i class="el-icon-s-home"></i>
        {{ $t("mainNavTitle") }}
      </div>

      <div class="search-input">
        <el-popover placement="bottom" trigger="manual" width="1200" class="result-base" v-model="popoverVisible">
          <div class="search-popover-container" @mousedown.stop>
            <div class="search-cluster">
              <div
                v-for="(item, index) in result"
                :key="index"
                class="search-cluster-item"
                @click="getClickCluster(item)"
              >
                <el-tooltip
                  class="search-cluster-tooltip"
                  effect="dark"
                  :content="getClusterResultCount(item)"
                  placement="top-start"
                  :open-delay="800"
                >
                  <div class="search-cluster-item-cluster">{{ item.providerTopology.cluster }}</div>
                </el-tooltip>
                <div class="search-cluster-item-count">{{ getCount(item) }}</div>
              </div>
            </div>
            <el-divider direction="vertical" />
            <div class="search-cluster-result">
              <div class="resource-container" v-for="(resource, index) in this.showPanel" :key="index">
                <div class="resource-title">
                  <i class="el-icon-menu"></i>
                  {{ resource.type }}
                </div>
                <div
                  class="resource-item"
                  v-for="(sample, index) in resource.samples"
                  :key="index"
                  @click="toResultDetail(resource.type, sample.labels)"
                >
                  <div class="name">
                    {{ sample.labels.name }}
                  </div>

                  <div class="value" v-if="['PersistentVolumeClaims', 'PersistentVolume'].includes(resource.type)">
                    {{ sample.value }}{{ resource.unit }}
                  </div>

                  <div class="time" v-else>
                    {{ moment(sample.value * 1000).format("YYYY-MM-DD HH:mm:ss ") }}
                  </div>

                  <div class="namespace">
                    {{ sample.labels.namespace }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div slot="reference" @mousedown.stop>
            <el-input
              v-model="searchText"
              @keyup.enter.native="search"
              @focus="searchInputFocus"
              @input="searchInputEvent"
            >
              <el-button slot="append" type="primary" icon="el-icon-search" @click="search"></el-button>
            </el-input>
          </div>
        </el-popover>
      </div>
    </div>

    <div class="control-component-container">
      <div class="control-panel-title">
        <svg-icon icon-class="Cluster"></svg-icon>
        {{ $t("Cluster") }}
      </div>

      <div class="item-container">
        <div
          class="item"
          v-for="(item, index) in providers"
          :key="index"
          :class="{ ready: item.ready, 'not-ready': !item.ready, deny: item.status === 'Deny' }"
        >
          <div class="item-title">
            {{ item.cluster }}
          </div>

          <img :src="aws" v-if="item.kind == 'ProviderAWS'" />
          <img :src="aliyun" v-if="item.kind == 'ProviderALiCloud'" />
          <img :src="gcp" v-if="item.kind == 'ProviderGCP'" />
          <img :src="onpremise" v-if="item.kind == 'ProviderKubeconfig'" />
          <img :src="tencentcloud" v-if="item.kind == 'ProviderTencent'" />
          <img :src="huaweicloud" v-if="item.kind == 'ProviderHuawei'" />

          <div class="name-value">
            <span class="name">{{ $t("status") }}: </span>
            <div class="status success" v-if="item.ready">Ready</div>
            <div class="status danger" v-else>NotReady</div>
          </div>

          <div class="name-value">
            <span class="name">{{ $t("region") }}: </span>
            <span class="value">{{ item.regionID }}</span>
          </div>

          <div class="name-value">
            <span class="name">{{ $t("vendor") }}: </span>
            <span class="value">{{ item.kind }}</span>
          </div>

          <div class="name-value">
            <span class="name">{{ $t("role") }}: </span>
            <span class="value">{{ item.roles.map(item => item.roleName).join("/") }}</span>
          </div>

          <div class="name-value">
            <span class="name">{{ $t("effect") }}: </span>
            <div class="status error" v-if="item.status === 'Deny'">{{ $t("effectDeny") }}</div>
            <div class="status success" v-else>{{ $t("effectAllow") }}</div>
          </div>
        </div>

        <div class="empty-text" v-if="!providers || providers.length == 0">
          <a href="javascript:;" @click="$router.push('/admin/cluster/add')">{{ $t("emptyCluster") }}</a>
        </div>
      </div>
    </div>

    <div class="control-component-container">
      <div class="control-panel-title">
        <svg-icon icon-class="history"></svg-icon>
        {{ $t("history") }}
      </div>

      <div class="item-container">
        <div class="item record" v-for="(item, index) in recordList" :key="index">
          <div class="item-title">
            <a @click="toDetail(item.fullPath)"> {{ item.name }}</a>
          </div>

          <svg-icon :icon-class="item.type" class-name="type-icon"></svg-icon>

          <div class="name-value">
            <span class="name">{{ $t("type") }}: </span>
            <span class="value">{{ item.type }}</span>
          </div>

          <div class="name-value" v-if="item.namespace">
            <span class="name">{{ $t("Namespace") }}: </span>
            <span class="value">{{ item.namespace ? item.namespace : "-" }}</span>
          </div>

          <div class="name-value">
            <span class="name">{{ $t("Cluster") }}: </span>
            <span class="value">{{ item.cluster }}</span>
          </div>

          <div class="name-value">
            <span class="name">{{ $t("region") }}: </span>
            <span class="value">{{ item.region }}</span>
          </div>

          <div class="name-value">
            <span class="name">{{ $t("vendor") }}: </span>
            <span class="value">{{ item.vendor }}</span>
          </div>
        </div>

        <div class="empty-text" v-if="recordList.length < 1">
          {{ $t("empty") }}
        </div>
      </div>
    </div>

    <el-row :gutter="20">
      <el-col :span="18">
        <div class="common-container-style">
          <div class="control-panel-title">
            <svg-icon icon-class="tool"></svg-icon>
            {{ $t("KubeStarTools") }}
          </div>

          <el-row :gutter="20">
            <el-col :span="8">
              <div class="tool-container">
                <div>
                  <router-link to="/cluster-dashboard">
                    <div class="list-title">
                      {{ $t("tools.clusterMonitor") }}
                    </div>
                  </router-link>

                  <div class="desc">
                    {{ $t("tools.clusterMonitorDescription") }}
                  </div>
                </div>

                <img src="~@/assets/illustration/cluster-monitor.svg" />
              </div>
            </el-col>

            <el-col :span="8">
              <div class="tool-container">
                <div>
                  <router-link to="/audit">
                    <div class="list-title">
                      {{ $t("tools.audit") }}
                    </div>
                  </router-link>

                  <div class="desc">
                    {{ $t("tools.auditDescription") }}
                  </div>
                </div>

                <img src="~@/assets/illustration/audit.svg" />
              </div>
            </el-col>

            <el-col :span="8">
              <div class="tool-container">
                <div>
                  <router-link to="/add/import">
                    <div class="list-title">
                      {{ $t("tools.importYaml") }}
                    </div>
                  </router-link>
                  <div class="desc">
                    {{ $t("tools.importYamlDescription") }}
                  </div>
                </div>

                <img src="~@/assets/illustration/import-yaml.svg" />
              </div>
            </el-col>

            <!-- <el-col :span="6">
              <div class="tool-container">
                <div>
                  <router-link to="/appstore/list">
                    <div class="list-title">
                      {{ $t("appStore") }}
                    </div>
                  </router-link>
                  <div class="desc">
                    KubeStar官方应用商店
                  </div>
                </div>
                <img src="~@/assets/illustration/app-store.svg" />
              </div>
            </el-col> -->
          </el-row>
        </div>
      </el-col>

      <el-col :span="6">
        <div class="common-container-style">
          <div class="control-panel-title">
            <svg-icon icon-class="doc"></svg-icon>
            {{ $t("KubeStarDocs") }}
          </div>

          <div class="tool-container">
            <div class="docs-link">
              <a :href="'http://docs.kubestar.io/' + docsType" target="_blank">
                <i class="el-icon-link"></i>
                Docs Link
              </a>
            </div>

            <img src="~@/assets/illustration/docs.svg" style="width: 130px; " />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import provider from "@/mixins/provider";
import { searchGlobal } from "api/monitor";
import { getCookies } from "utils/cookies";
export default {
  mixins: [provider],
  components: {
    // Result
  },
  data() {
    return {
      searchText: "",
      popoverVisible: false,
      searchLoading: true,
      result: [],
      showPanel: [],
      ifClickCluster: true,
      chosenItem: null
    };
  },
  methods: {
    search() {
      this.showPanel = [];
      this.result = [];
      if (this.searchText) {
        this.searchLoading = true;
        this.popoverVisible = true;
        searchGlobal(this.searchText).then(response => {
          if (response.code === 0) {
            this.searchLoading = false;
            let sortData = response.data;
            // 根据查询的总结果排序
            sortData.sort(function(a, b) {
              let a_count = 0;
              for (let i = 0; i < a.resources.length; i++) {
                a_count += a.resources[i].samples.length;
              }
              let b_count = 0;
              for (let i = 0; i < b.resources.length; i++) {
                b_count += b.resources[i].samples.length;
              }
              if (a_count < b_count) {
                return 1;
              }
              if (a_count > b_count) {
                return -1;
              }
              return 0;
            });
            this.result = sortData;
            if (sortData.length > 0) {
              this.getClickCluster(sortData[0]);
            }
          }
        });
      }
    },
    searchInputFocus() {
      if (this.searchText && this.result.length > 0) {
        this.popoverVisible = true;
      }
    },
    searchInputEvent() {
      if (!this.searchText) {
        this.popoverVisible = false;
        this.result = [];
      }
    },
    toDetail(path) {
      this.$emit("routerGo");
      this.$router.push({
        path
      });
    },
    getClusterResultCount(item) {
      let count = 0;
      for (let i = 0; i < item.resources.length; i++) {
        count += item.resources[i].samples.length;
      }
      return item.providerTopology.cluster + "(" + count + ")";
    },
    getCount(item) {
      let count = 0;
      for (let i = 0; i < item.resources.length; i++) {
        count += item.resources[i].samples.length;
      }
      return "(" + count + ")";
    },
    toResultDetail(type, label) {
      this.searchText = "";
      this.popoverVisible = false;
      let { name, namespace } = label;
      let { cluster, region, vendor } = this.chosenItem.providerTopology;
      let query = namespace
        ? { vendor: vendor, region: region, cluster: cluster, namespace: namespace }
        : { vendor: vendor, region: region, cluster: cluster };
      this.$emit("routerGo");
      let url = this.$router.resolve({
        path: `/detail/${type}/${name}`,
        query: query
      });
      window.open(url.href, "_blank");
    },
    getClickCluster(item) {
      this.ifClickCluster = true;
      this.chosenItem = item;
      this.showPanel = [];
      let helpMap = new Map();
      for (let i = 0; i < item.resources.length; i++) {
        helpMap.set(item.resources[i].type, item.resources[i]);
      }
      // 排序
      if (helpMap.has("Deployment")) {
        this.showPanel.push(helpMap.get("Deployment"));
      }
      if (helpMap.has("Pod")) {
        this.showPanel.push(helpMap.get("Pod"));
      }
      if (helpMap.has("StatefulSet")) {
        this.showPanel.push(helpMap.get("StatefulSet"));
      }
      if (helpMap.has("Service")) {
        this.showPanel.push(helpMap.get("Service"));
      }
      if (helpMap.has("Ingress")) {
        this.showPanel.push(helpMap.get("Ingress"));
      }
      if (helpMap.has("ConfigMap")) {
        this.showPanel.push(helpMap.get("ConfigMap"));
      }
      if (helpMap.has("Namespace")) {
        this.showPanel.push(helpMap.get("Namespace"));
      }
      if (helpMap.has("Secret")) {
        this.showPanel.push(helpMap.get("Secret"));
      }
      if (helpMap.has("CronJob")) {
        this.showPanel.push(helpMap.get("CronJob"));
      }
      if (helpMap.has("Node")) {
        this.showPanel.push(helpMap.get("Node"));
      }
      if (helpMap.has("PersistentVolume")) {
        this.showPanel.push(helpMap.get("PersistentVolume"));
      }
      if (helpMap.has("PersistentVolumeClaims")) {
        this.showPanel.push(helpMap.get("PersistentVolumeClaims"));
      }
      if (helpMap.has("StorageClass")) {
        this.showPanel.push(helpMap.get("StorageClass"));
      }
      if (helpMap.has("DaemonSet")) {
        this.showPanel.push(helpMap.get("DaemonSet"));
      }
      this.ifClickCluster = false;
    }
  },
  computed: {
    userName() {
      return this.$store.state.app.userName;
    },
    providers() {
      return this.$store.state.app.userInfo.providers;
    },
    recordList() {
      return this.$store.state.app.recordList;
    },
    docsType() {
      let lang = getCookies("language");
      let docsType = "en/";
      switch (lang) {
        case "en":
          docsType = "en/";
          break;
        case "ja":
          docsType = "en/";
          break;
        case "zh":
          docsType = "cn/";
          break;
      }
      return docsType;
    }
  },
  mounted() {
    document.addEventListener("mousedown", () => {
      this.popoverVisible = false;
    });
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
.control-panel-title {
  @include title(14px);
  margin-bottom: 20px;
  @include flex(flex-start);
  .svg-icon {
    width: 22px !important;
    height: 22px !important;
  }
}
.kubestar-title {
  @extend .control-panel-title;
  @include title(18px);
}
.search-container {
  background-image: url("../../assets/search-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  .search-title {
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin-top: 30px;
  }
  .search-input {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .el-input-group {
      width: 1200px;
    }
    ::v-deep .el-input__inner {
      &:focus {
        border-color: transparent;
      }
    }
  }
}
.common-container-style {
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: rgb(250, 251, 252);
}
.control-component-container {
  overflow-x: auto;
  @extend .common-container-style;
  .item-container {
    display: inline-flex;
  }
  .item {
    position: relative;
    img,
    .type-icon {
      position: absolute;
      opacity: 0.4;
      right: 0;
      bottom: 10px;
    }
    img {
      width: 80px;
    }
    vertical-align: top;
    margin-right: 30px;
    border-left: 4px solid #99ccff;
    background: #fff;
    padding: 16px 10px 0;
    width: 240px;
    box-sizing: border-box;
    border-radius: 8px;
    &.ready {
      border-left-color: #99ccff;
    }
    &.not-ready {
      border-left-color: #ff6666;
    }
    &.deny {
      background-color: #f4f4f5;
      border-left-color: #e9e9eb;
      cursor: not-allowed;
    }
    // &.record {
    //   border-left-color: #66ccff;
    // }
    box-shadow: $common-shadow;
  }
  .item-title {
    @include title(14px);
    margin-bottom: 8px;
    a {
      color: $color-primary;
    }
  }
}
.docs-link {
  & > a {
    display: block;
    font-size: 12px;
    line-height: 26px;
    color: $color-main;
    font-weight: 700;
    i {
      color: $color-primary;
      font-size: 18px;
    }
  }
}
.tool-container {
  @extend .common-container-style;
  margin-bottom: 0;
  background-color: #fff;
  @include flex($wrap: nowrap);
  .list-title {
    @include title(14px);
    display: inline-block;
    margin-bottom: 4px;
  }
  img {
    height: 90px;
  }
}
.search-popover-container {
  height: 500px;
  display: flex;
  /*overflow-y: auto;*/
  & > div {
    min-height: 50px;
  }
  .title {
    color: $color-sub;
  }
  //background-color: #fafbfc;
}
.resource-container {
  margin-bottom: 10px;
  .resource-title {
    @include title(16px);
  }
  .resource-item {
    cursor: pointer;
    line-height: 30px;
    @include flex(flex-start, center, nowrap);
    font-size: 12px;
    padding: 0 10px;
    &:hover {
      background: #f1f5f8;
    }
    .name {
      flex: 1;
    }
    .namespace,
    .time,
    .value {
      width: 160px;
      @include text-overflow();
    }
  }
}
</style>

<style lang="scss">
.search-cluster {
  width: 500px;
  overflow-y: auto;
  display: inline-block;
}
.search-cluster-item {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  text-align: center;
  line-height: 37px; /*设置line-height与父级元素的height相等*/
  overflow: hidden; /*防止内容超出容器或者产生自动换行*/
  &:hover {
    background: #eff7ff;
  }
  margin-left: 0 !important;
  display: flex;
}
.search-cluster-item-cluster {
  width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  margin-left: 20px;
}
.search-cluster-item-count {
  width: 20%;
  font-size: 15px;
  margin-left: 10px;
}
.search-cluster-result {
  width: 100%;
  overflow-y: auto;
}
.el-divider--vertical {
  display: inline-block;
  width: 1px;
  height: 100% !important;
  margin: 0 8px;
  vertical-align: middle;
  position: relative;
}
</style>
