export default {
    Gateway: "网关",
    GatewayClass: "网关类",
    HTTPRoute: "HTTP 路由",
    gatewayName: "网关名称",
    gatewayClassName: "网关类",
    gatewayAddress: "网关地址",
    gatewayStatus: "网关状态",

    gatewayBasicInformation: "网关基本信息",

    gatewayClassController: "控制器",
    gatewayClassStatus: "网关类状态",
    gatewayClassDesc: "网关类描述",

    addServiceForRule: "为匹配规则引用后端服务",
    httpRouteHostNames: "域名",
    rule: "规则",
    matchRule: "匹配规则",
    httpRouteMatch: "HTTP路径匹配",
    pathMatch: {
        Exact: "精确匹配",
        PathPrefix: "路径前缀匹配",
        RegularExpression: "正则表达式匹配"
    },
    backendService: "后端服务",
    serviceWeight: "服务权重"
};
