export default {
  handle: {
    submit: "Submit",
    detail: "Detail",
    confirm: "Confirm",
    cancel: "Cancel",
    handle: "Handle",
    edit: "Edit",
    delete: "Delete",
    add: "Add",
    create: "Create",
    save: "Save",
    publish: "Publish",
    search: "Search",
    submitAgain: "Submit again",
    backLast: "Previous page",
    backHome: "Back home",
    backLogin: "Login",
    clusterTest: "Cluster connection test",
    resume: "Resume",
    pause: "Pause",
    registryTest: "Image registry connection test",
    addCluster: "New cluster",
    addRegistry: "New image registry",
    restart: "Restart",
    update: "Update",
    rollback: "Rollback",
    hotfix: "Hotfix",
    scale: "Scale",
    collect: "Collect",
    cancelCollect: "Cancel collect",
    permission: "Permissions",
    addNodePort: "New port",
    addService: "New service",
    toRollback: "Rollback to this version",
    toYAML: "YAML diff",
    test: "Test",
    testing: "Testing",
    nextStep: "Next step",
    lastStep: "Last step",
    envAddKeyVal: "New environment variable",
    envAddKeyFrom: "New environment key value with Configmap or Secret",
    nodeResume: "Resume scheduling",
    nodePause: "Unscheduled",
    nodeDrain: "Drain Node",
    editMonitor: "Edit monitor info",
    refresh: "Refresh",
    moreHandle: "More operations",
    import: "Import",
    cancelImport: "Cancel Import",
    toLogs: "Container console log",
    toTerminal: "Web Terminal",
    aboutUs: "About",
    quickCreate: "[Quick Create]",
    configMapAddItem: "New configmap item",
    addHostAlias: "New host aliases",
    addAlias: "New alias",
    upload: "Upload file",
    batchUpload: "Batch upload files",
    input: "Input text",
    openRefresh: "Refresh on",
    closeRefresh: "Refresh off",
    addAnnotation: "Add annotations",
    addReceiver: "Add receiver",
    addEmailConf: "Email setup",
    addSlackConf: "Slack setup",
    addWeChatConf: "Wechat setup",
    addPhoneConf: "Tel/SMS setup",
    addPhoneNumber: "Tel number",
    moreConf: "More",
    slideUpConf: "Fold",
    addSilenceConf: "Silence setup",
    reCreate: "Recreate",
    addSilenceLabel: "Add silence labels",
    addAlarmRules: "Add alert rules",
    addNodeLabels: "Add node labels",
    addNodeAffinityReq: "Add Node Require Affinity",
    addNodeAffinityPrefer: "Add Node Prefer Affinity",
    addPodAffinityLabels: "Add Pod affinity requiredDuringSchedulingIgnoredDuringExecution labels",
    addPodPreferAffinityLabels: "Add Pod affinity requiredDuringSchedulingIgnoredDuringExecution labels",
    addPodAntiAffinityLabels: "Add Pod anti-affinity labels",
    addMatchExpression: "Add MatchExpression",
    addMatchField: "Add MatchField",
    addLabelSelector: "Add LabelSelector",


    searchService: "Search",
    addToleration: "Add tolerations",
    batchScaleByHand: "Batch scale out by hand",
    batchScaleAuto: "Batch scale out automatically",
    batchDelete: "Batch deletion",
    batchHandle: "Batch handling",
    addMetricTemplate: "Add monitoring metric collection entry",
    addMetricTemplateBlack: "Black box monitioring",
    addMetricTemplateWhite: "White box monitoring",
    clickConf: "Click",
    generateInvitationInfo: "Generate invitation information",
    copy: "Copy",
    batchImport: "Batch import",
    hideText: "Hide Text",
    displayText: "Display Text",
    inputByHand: "Manual input",
    inputBySelect: "Select input",

    rollbackNow: "One-click rollback",
    currentVersion: "Current Version"
  }
};
