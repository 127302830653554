import http from "../http";

export const configMapList = params => {
  return http({
    method: "get",
    params,
    url: `/k8s.kubestar.io/configmaps`
  });
};

export const configMapListByLocation = (data, params) => {
  return http({
    method: "get",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/configmaps`,
    params
  });
};

export const configMapSubmit = (data, clusterLoc) => {
  data.apiVersion = "v1"
  data.kind = "ConfigMap"
  return http({
    method: "post",
    url: `/k8s.kubestar.io/vendors/${clusterLoc.vendor}/regions/${clusterLoc.region}/clusters/${clusterLoc.cluster}/namespaces/${data.metadata.namespace}/configmaps`,
    data
  });
};

export const configmapCopy = (namespace, data, clusterLoc) => {
  data.apiVersion = "v1"
  data.kind = "ConfigMap"
  return http({
    method: "put",
    url: `/k8s.kubestar.io/vendors/${clusterLoc.vendor}/regions/${clusterLoc.region}/clusters/${clusterLoc.cluster}/namespaces/${namespace}/configmaps`,
    data
  });
};

export const configMapDetail = data => {
  return http({
    method: "get",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/configmaps/${data.configmap}`
  });
};

export const configMapUpdate = (data, clusterLoc) => {
  data.apiVersion = "v1"
  data.kind = "ConfigMap"
  return http({
    method: "put",
    url: `/k8s.kubestar.io/vendors/${clusterLoc.vendor}/regions/${clusterLoc.region}/clusters/${clusterLoc.cluster}/namespaces/${data.metadata.namespace}/configmaps/${data.metadata.name}`,
    data
  });
};

export const configMapDelete = data => {
  return http({
    method: "delete",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/configmaps/${data.name}`
  });
};


