<template>
  <div>
    <el-form ref="form" :model="form" size="small" label-position="top">
      <el-form-item :label="$t('organizationID')">
        <span class="help">
          <i class="el-icon-document-copy" v-clipboard:copy="organization"></i>
          {{ organization }}
        </span>
      </el-form-item>

      <el-form-item :label="$t('userID')" v-if="!create">
        <span class="help">
          <i class="el-icon-document-copy" v-clipboard:copy="form.uuid"></i>
          {{ form.uuid }}
        </span>
      </el-form-item>

      <el-form-item
        :label="$t('name')"
        prop="name"
        :rules="
          create
            ? [
                { required: true, message: $t('rules.require'), trigger: 'blur' },
                {
                  pattern: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
                  message: $t('rules.pattern'),
                  trigger: ['blur', 'change']
                }
              ]
            : [{ required: true, message: $t('rules.require'), trigger: 'blur' }]
        "
      >
        <el-input v-model="form.name" :disabled="!create"></el-input>
        <span class="help">{{ $t("tips.nameCommonPattern") }}</span>
      </el-form-item>

      <el-form-item
        :label="$t('email')"
        prop="email"
        v-if="general || create"
        :rules="[
          { required: true, message: $t('rules.require'), trigger: 'blur' },
          { type: 'email', message: $t('rules.pattern'), trigger: ['blur', 'change'] }
        ]"
      >
        <el-input v-model="form.email"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('password')"
        prop="password"
        v-if="password || create"
        :rules="[
          { required: true, message: $t('rules.require'), trigger: 'blur' },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{}|']).{8,}$/,
            message: $t('rules.login.password'),
            trigger: ['blur', 'change']
          }
        ]"
      >
        <el-input v-model="form.password" type="password" show-password></el-input>
        <span class="help">{{ $t("rules.login.password") }}</span>
      </el-form-item>

      <el-form-item
        :label="$t('confirmPassword')"
        prop="verifyPassword"
        v-if="password || create"
        :rules="[{ required: true, validator: validatePassConfirm, trigger: ['blur', 'change'] }]"
      >
        <el-input v-model="form.verifyPassword" type="password" show-password></el-input>
      </el-form-item>

      <el-form-item :label="$t('isAdmin')" v-if="permission || create">
        <el-radio-group v-model="organizationAdminFlag">
          <el-radio-button :label="true">YES</el-radio-button>
          <el-radio-button :label="false">NO</el-radio-button>
        </el-radio-group>
      </el-form-item>

<!--      <el-form-item :label="$t('bindUserGroup')" v-if="permission || create">-->
<!--        <el-select v-model="groupUserBindings" filterable multiple style="width: 100%;" v-if="groupList.length > 0">-->
<!--          <el-option v-for="item in groupList" :key="item.uuid" :label="item.name" :value="item.uuid">-->
<!--            <div>-->
<!--              <div class="role-title">{{ item.name }}</div>-->
<!--              <div class="role-desc">{{ item.description }}</div>-->
<!--            </div>-->
<!--          </el-option>-->
<!--        </el-select>-->

<!--        <span class="help" v-else>-->
<!--          <a href="javascript:;" @click="$router.push('/admin/group/add')"> {{ $t("emptyUserGroup") }}</a>-->
<!--        </span>-->

<!--        <span class="help">-->
<!--          {{ $t("emptyUserGroupTips") }}-->
<!--        </span>-->
<!--      </el-form-item>-->

      <el-form-item :label="$t('userPermission')" v-if="permission || create">
        <el-table
          :data="providers"
          v-if="providers.length > 0"
          highlight-current-row
          @expand-change="expandSelect"
          :row-key="getRowKeys"
          :expand-row-keys="expands"
        >
          <el-table-column label="#" width="150">
            <template v-slot="scope">
              <img :src="aws" v-if="scope.row.kind == 'ProviderAWS'" />
              <img :src="aliyun" v-if="scope.row.kind == 'ProviderALiCloud'" />
              <img :src="gcp" v-if="scope.row.kind == 'ProviderGCP'" />
              <img :src="onpremise" v-if="scope.row.kind == 'ProviderKubeconfig'" />
              <img :src="tencentcloud" v-if="scope.row.kind == 'ProviderTencent'" />
              <img :src="huaweicloud" v-if="scope.row.kind == 'ProviderHuawei'" />
            </template>
          </el-table-column>

          <el-table-column :label="$t('Cluster')" prop="cluster">
            <template v-slot="scope">
              <div class="title">{{ scope.row.cluster }}</div>
              <div class="desc">{{ scope.row.uuid }}</div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('role')">
            <template v-slot="scope">
              <el-select size="small" filterable v-model="groupUserRoleBindings[scope.row.uuid]">
                <el-option v-for="item in roleList" :key="item.uuid" :label="item.name" :value="item.uuid">
                  <div>
                    <div class="role-title">{{ $t("role") }}: {{ item.name }}</div>
                    <div class="role-desc">{{ item.description ? item.description : "-" }}</div>
                  </div>
                </el-option>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column :label="$t('effect')">
            <template v-slot="scope">
              <el-radio-group v-model="effectBindings[scope.row.uuid]">
                <el-radio-button label="Allow">{{ $t("effectAllow") }}</el-radio-button>
                <el-radio-button label="Deny">{{ $t("effectDeny") }}</el-radio-button>
              </el-radio-group>
            </template>
          </el-table-column>

          <el-table-column :label="$t('Namespace')" type="expand" width="100px">
            <template v-slot="scope">
              <div class="namespace_transfer">
                <el-transfer
                    v-model="selectNamespaces[scope.row.uuid]"
                    :props="{
                  key: 'name',
                  label: 'name'
                }"
                    :data="selectAllNamespaces[scope.row.uuid]"
                    :titles="[$t('allNamespacesTips'), $t('whitelistNamespacesTips')]"
                    filterable
                    :filter-method="filterMethod"
                    @change="(value, direction, movedKeys) => namespaceChange(value, direction, movedKeys, scope)"
                >
                </el-transfer>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <span class="help" v-else>
          <a href="javascript:;" @click="$router.push('/admin/cluster/add')">{{ $t("emptyCluster") }}</a>
        </span>
      </el-form-item>

      <slot name="button"></slot>
    </el-form>
  </div>
</template>

<script>
import provider from "@/mixins/provider";
import { providers } from "api/app";
import { roleList } from "api/role";
import { groupList } from "api/group";
import { namespaceListByProvider } from "api/namespace";

export default {
  props: {
    form: Object,
    create: Boolean,
    general: Boolean,
    password: Boolean,
    permission: Boolean
  },
  mixins: [provider],

  data() {
    return {
      expands: [],
      providers: [],
      roleList: [],
      groupList: [],
      groupUserRoleBindings: {},
      effectBindings: {},
      groupUserBindings: [],
      groupUserProviderFilter: [],
      organizationAdminFlag: false,
      selectNamespaces: {},
      selectAllNamespaces: {}
    };
  },

  methods: {
    getRowKeys(row) {
      return row.id;
    },

    filterMethod(query, item) {
      return item.name.indexOf(query) > -1;
    },

    // 穿梭框的移动事件
    namespaceChange(value, direction, movedKeys, scope) {
      if (direction === "right") {
        movedKeys.forEach(key => {
          const _groupUserProviderFilter = {};
          _groupUserProviderFilter.organizationUUID = this.organization;
          _groupUserProviderFilter.userUUID = this.form.uuid;
          _groupUserProviderFilter.providerUUID = scope.row.uuid;
          _groupUserProviderFilter.namespace = key;
          this.groupUserProviderFilter.push(_groupUserProviderFilter);
        });
      } else {
        movedKeys.forEach(key => {
          this.groupUserProviderFilter.forEach((item,index) => {
            if (item.namespace === key) {
              this.groupUserProviderFilter.splice(index, 1);
            }
          });
        });
      }

      this.providers.splice(1, 0);
    },

    expandSelect(row, expandedRows) {
      if (expandedRows.length) {
        this.expands = [];
        if (row) {
          this.expands.push(row.id);
        }
      } else {
        this.expands = [];
      };
    },

    async getProviders() {
      await providers(this.organization).then(response => {
        if (response.code === 0) {
          this.providers = response.data.items;

          if (this.create)
            this.providers.forEach(item => {
              this.$set(this.effectBindings, item.uuid, "Allow");
            });
        }
      });
    },

    async getRoleList() {
      await roleList(this.organization).then(response => {
        if (response.code === 0) {
          this.roleList = response.data.items;
        }
      });
    },

    async getGroupList() {
      await groupList(this.organization).then(response => {
        if (response.code === 0) {
          this.groupList = response.data.items;
        }
      });
    },

    async getUserProviderNamespaceInfo() {
      await namespaceListByProvider().then(response => {
        if (response.code === 0) {
          this.providers.forEach(item => {
            this.$set(this.selectNamespaces, item.uuid, []);
          });
          this.selectAllNamespaces = response.data;
          this.groupUserProviderFilter.forEach(provider => {
            this.selectNamespaces[provider.providerUUID].push(provider.namespace);
          });
        }
      });
    },

    validatePassConfirm(rule, value, callback) {
      if (value === "") {
        callback(new Error(this.$t("rules.login.passwordConfirm")));
      } else {
        if (value !== this.form.password) {
          callback(new Error(this.$t("rules.login.passwordDiff")));
        }
        callback();
      }
    }
  },

  mounted() {
    if (this.permission || this.create) {
      this.getProviders();
      this.getRoleList();
      this.getGroupList();
    }
    if (this.permission) {
      this.getUserProviderNamespaceInfo();
    }
  },

  computed: {
    organization() {
      return this.$store.state.app.organization;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.el-select-dropdown__item {
  min-height: 60px;
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 120px;
  margin-left: 10px;
  vertical-align: bottom;
}

.namespace_transfer {
  :deep(.el-transfer-panel){
    width:350px !important;
  }
}

.role-title {
  font-size: 12px;
  color: $color-main;
  font-weight: 600;
}

.role-desc {
  font-size: 12px;
  color: $color-sub;
  line-height: 1;
}
</style>
