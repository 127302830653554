export default {
  tips: {
    nameCommonPattern: "必填项，允许小写字母/数字/短横线组成",
    application: "应用名称，必填项，允许小写字母/数字/短横线组成",
    replicas: "应用的实例个数",
    terminationGracePeriodSeconds: "应用退出等待时间，超过该时间应用会强制停止",
    podManagementPolicy: "容器组在创建、扩容时的规则",
    updateStrategy: "容器组滚动升级策略",
    partition: "当滚动升级策略为RollingUpdate时，需要设置分片，指定升级时候的阶段",
    schedule: "符合crontab规则，例如(每天2点执行: 0 2 * * *)",
    concurrencyPolicy: "并发方式指上一个没有完成，到下一个任务时间时是否应该正常启动",
    startingDeadlineSeconds: "错过调度时间多少秒内仍可以调度",
    suspend: "是否暂停运行，如果状态为ON，已经定时任务将会暂停执行任务",
    activeDeadlineSeconds: "Job最大持续运行时间，超过此时间的Pod将会被杀死，防止Pod长时间运行",
    backoffLimit: "Pod运行失败后重试次数",
    startCommand: "应用要执行的启动命令，通常提供执行参数",
    postStart: "应用启动后要执行的动作，可以是脚本、HTTP地址或Socket端口，通常用来注册服务",
    preStop: " 应用退出前要执行的动作，可以是脚本、HTTP地址或Socket端口，通常用来注销服务",
    livenessProbe:
      "检测应用健康状态，动作可以是脚本、HTTP地址或Socket端口，通常用来检测应用负载，如果多次执行失败应用会被重启",
    readinessProbe:
      "检测应用是否就绪，动作可以是脚本、HTTP地址或Socket端口，通常用来检测应用是否可以接收请求，如果多次执行失败请求不会转发到该应用",
    parallelism: "指定任务在给定时间点的Pod最大并发数",
    completions: "指定任务完成所需的Pod数量",
    addlabels: "labels格式为 Key:Val",
    imageText: "此镜像在系统中未找到, 仅支持手动填写。",
    selectExistImage: " 选择当前系统镜像仓库中的镜像",
    labelSearch: "如果您按照标签搜索，搜索条件格式为 Key:Val 形式",
    addContainerPorts: "选择您要添加端口的类型",
    location: "选择将要发布的集群",
    maxUnavailable: "在滚动更新时，我们可以忍受多少个 Pod 无法提供服务",
    maxSurge: "在滚动更新时，可以有多少个额外的 Pod",
    checkoutNamespace: "切换Namespce后，需重新填写容器组配置",
    containerConfTooltipText: "请提交或删除正在编辑的容器组配置",
    serviceConfTooltipText: "请提交或删除正在编辑的服务配置",
    containerConfigMap: "使用 ConfigMap 来将你的配置数据和应用程序代码分开",
    containerSecret: "Secret 对象类型用来保存敏感信息，例如密码、OAuth 令牌和 SSH 密钥",
    containerVolume:
      "Container 中的文件在磁盘上是临时存放的，这给 Container 中运行的较重要的应用 程序带来一些问题。问题之一是当容器崩溃时文件丢失。kubelet 会重新启动容器， 但容器会以干净的状态重启。 第二个问题会在同一 Pod 中运行多个容器并共享文件时出现。 Kubernetes 卷（Volume） 这一抽象概念能够解决这两个问题。",
    containerPersistentVolume:
      "持久卷（PersistentVolume，PV）是集群中的一块存储，可以由管理员事先供应，或者 使用存储类（Storage Class）来动态供应。 持久卷是集群资源，就像节点也是集群资源一样。PV 持久卷和普通的 Volume 一样，也是使用 卷插件来实现的，只是它们拥有独立于任何使用 PV 的 Pod 的生命周期。",
    containerLiftCycleTitle: "为容器设置启动时要执行的命令和参数",
    containerLiftCycle:
      "创建 Pod 时，可以为其下的容器设置启动时要执行的命令及其参数，注意，一旦 Pod 创建完成，该命令及其参数就无法再进行更改了",
    containerHealthCheckTitle: " 配置存活、就绪和启动探测器",
    appAndService: "应用与服务",
    searchHistory: "搜索历史",
    serviceUnAssociatedAppWarningTitle: "Service没有关联应用",
    serviceUnAssociatedAppWarningDesc: "Label Selector 未匹配任何应用及Pod，请检查确认。",
    showMore: "查看",
    copyResult: "复制结果",
    result: "结果",
    copying: "复制中",
    copySuccess: "复制成功",
    copyFailed: "复制失败",
    LabelSelectorTips: "请输入标签，格式为key:value",
    LabelSelectorError: '输入格式错误，必须包含":"'
  },

  albAnnotationsHelp: "查看ALB 注解的具体指南",
  empty: "暂无相关资源",
  emptyParams: "暂无{0}监控数据",
  emptyInLocation: "您选择的【{0}】下暂无相关资源",
  emptyResource: "暂无{0}",
  success: "操作成功",
  error: "操作失败",

  step: "步骤",
  addClusterStep: {
    1: "选择要添加的云服务商",
    2: "填写配置并测试集群连通性",
    3: "填写监控配置",
    4: "完成"
  },

  addRegistryStep: {
    1: "选择要添加的镜像仓库",
    2: "填写配置并测试镜像仓库连通性",
    3: "完成"
  },

  applicationListBatchStep: {
    1: "确认选择的应用",
    2: "确认操作"
  },

  registryTips: {
    needAuth: "开启后，拉取镜像时会自动创建imagePullSecret",
    test: "检测平台到镜像仓库的连通情况"
  },

  clusterTips: {
    test: "检测平台到集群的连通情况",
    monitor: "添加监控客户端以便监控集群状态"
  },

  moreConf: "更多配置",

  imageTips: "镜像名称的格式为：镜像仓库地址/镜像名称:tag",

  ingressTips: {
    ingressItemAdd: "添加域名对应的服务"
  },

  recommand: "根据您最近发布/更新历史，为您推荐的资源",

  addApplication: {
    import: "检测到您有 未发布/发布失败的数据，是够需要重新导入？",
    leavePrompt: "当前页面有未发布的数据，确定要退出吗？"
  },

  configMapTips: {
    mountPath: "该配置将以挂载路径指定的文件位置挂载到容器里",
    overlay: "该配置挂载到容器里时是否覆盖挂载路径里已有的文件",
    mode: "该配置挂载到容器里时所拥有的文件系统权限"
  },

  receiverTips: {
    wechatCorpID: "企业微信账号唯一 ID，可以在我的企业中查看",
    wechatAgentID: "第三方企业应用的 ID，可以在自己创建的第三方企业应用详情页面查看",
    wechatApiSecret: "第三方企业应用的密钥，可以在自己创建的第三方企业应用详情页面查看"
  },

  alarmTips: {
    duration: "当指标的实际值超过阈值，且持续时间超过您选择的时间，则会触发告警",
    alarmMessageContent: "使用: {{$value}} 获取指标的当前值"
  },

  affinityTips: {
    nodeAffinity: "希望Pod调度在哪个节点上",
    podAffinity: "希望Pod与哪些Pod调度在哪个节点上",
    podAntiAffinity: "不希望Pod与哪些Pod调度在哪个节点上",
    nodeKey: "当前Pod与硬亲和策略在一起的Pod，所运行节点的标签的Key值(必填，默认为kubernetes.io/hostname)",
    nodePreferKey: "当前Pod与软亲和策略在一起的Pod，所运行节点的标签的Key值(必填，默认为kubernetes.io/hostname)",
    antiNodeKey: "当前Pod与反亲和在一起的Pod，所运行节点的标签的Key值(必填，默认为kubernetes.io/hostname)"
  },

  imageHostTips: {
    SWRHost: "格式为：swr.{projectName}.myhuaweicloud.com",
    TCRHost: "格式为：{registryName}.tencentcloudcr.com"
  },

  listSelectResourceTips: "请选择要查看的资源详情",
  listHasSelectedApplications: "已选择应用",
  searchLabelKey: "搜索类别",

  timeSelectErrorTips: "开始时间不能大于结束时间",
  editAnnotationsTips:
    "修改 Annotation/Label 会触发Deployment, Statefulset, Daemonset, Cronjob, Pod 重启，不会影响Node状态",

  createClusterTips: "创建集群成功后，需要将用户或用户组绑定到此集群，并配置权限后，就可以使用此集群。立即前往{0}或{1}",
  monitorClusterDeny: "集群{0}在当前用户未绑定或已被禁用",
  copyInvitationInfo: "企业邀请信息已复制到粘贴板",
  generateInvitationInfoTips: "在企业列表处，可以生成企业邀请信息",
  emptyUser: "企业下暂无用户，您可以 [立即创建用户]",
  emptyCluster: "企业下暂无集群，您可以 [立即创建集群]",
  emptyUserGroup: "企业下暂无用户组，您可以不绑定用户组，或 [立即创建用户组]",
  emptyUserGroupTips: "用户首先会继承用户组的权限，若用户未指定所属用户组，才会继承设置的用户权限",
  emptyOrganization: "当前用户未绑定任何企业",
  emptyCategory: "没有要选择的类别，[快捷创建]",
  oneTimeMFACode: "一次性验证码",

  ResetMFAEmailSent: "一封重置MFA的验证码邮件已发送到你的注册邮箱",
  InputMFAResetEmail: "输入邮件中的验证码",
  snapshotDescription: "请输入快照的描述",

  sameSnapshotWarning: "应用及配置与最近快照无差异，无需重复创建快照",


  whatIsAKSK: "什么是AK/SK",
  AKSKTips: "通过API网关向KubeStar服务发送请求时，必须使用AK(Access Key ID)、SK(Secret Access Key)对请求进行签名。",

  importPanelTips1: "1.批量导入时，集群信息可能会发生变化，使用[选择Prometheus所属的集群]",
  importPanelTips2: "2.如当前Dashboard已存在面板，批量导入并不会将已存在的面板删除",

  createClusterSuccessTips: " 创建集群成功后，需要将用户或用户组绑定到此集群，并配置权限后，就可以使用此集群。"
};
