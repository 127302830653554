export default {
  imageSelect: "选择镜像",
  inputImageManually: "手动填写",
  imageSelected: "已选择",
  imageTagName: "标签名称",
  imageConfirm: "您还未选择镜像/镜像标签，确定退出?",
  currentRegistry: "当前镜像仓库: {0}",
  currentImage: "当前镜像: {0}",
  currentImageTag: "当前镜像标签: {0}",
  imageName: "镜像名称",
  imageTag: "镜像标签",
  needAuth: "拉取镜像时，是否需要认证？",
  registry: "镜像仓库",
  ca: "CA证书",

  uCloudAPIKeyHelp:
    '访问UCLOUD API的公钥，可以从UCLOUD的<a href="https://console.ucloud.cn/uapi/apikey">控制台</a>获取',
  uHubProjectID: "项目id，如果是默认项目，其他情况则不可为空",
  pullImagePasswd: "拉取镜像时所用到的密码, 您可能设置了独立的密码, 请确认后填写",

  appImageSetting: "拉取私有镜像设置",
  chooseKey: "选择拉取镜像密钥",
  ifNoPullImageSecret: "若无目标密钥，您可以",
  createPullImageSecretNow: "立即创建",
  createPullImageSecret: "创建密钥",
  createPullImageSecretHit: "密钥会创建在您选择的集群和命名空间下，",
  createPullImageSecretHostHit: "请勿以http或https开头，直接填写域名或IP即可"
};
