import store from "@/store/index.js";
import router from "@/router/index.js";
import {organizationList} from "api/organization";

export const loginAuth = async (loginInfo) => {

    await store.dispatch("app/setAppInfo", {...loginInfo})

    await router.push({path: "/home"}).catch(err => {
    })
};

export const initOrganizationList = async () => {

    await organizationList(store.state.app.userId).then(response => {
        if (response.code === 0) {
            store.dispatch("app/setOrganization", response.data.items)
        } else {
            router.push({path: "/unauthorized"}).catch(err => {})
        }
    }).catch(err => {
        router.push({path: "/unauthorized"}).catch(err => {})
    })
};

