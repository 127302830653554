export default {
  unit: {
    second: "second",
    min: "minute",
    time: {
      fifteen: "Last 15 Minutes",
      thirty: "Last 30 Minutes",
      oneHour: "Last 1 Hour",
      twoHour: "Last 2 Hours",
      fiveHour: "Last 5 Hours",
      twelveHour: "Last 12 Hours",
      oneDay: "Last 1 Day",
      threeDay: "Last 3 Days",
      sevenDay: "Last 7 Days"
    },
    line: "lines",
    all: "All"
  }
};
