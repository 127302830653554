import http from "../http";

export const clusterList = params => {
  return http({
    method: "get",
    params,
    url: `/k8s.kubestar.io/clusters`
  });
};

export const nodeList = params => {
  return http({
    method: "get",
    params,
    url: `/k8s.kubestar.io/nodes`
  });
};


export const clusterDetail = data => {
  return http({
    method: "get",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}`
  });
};

export const nodeDetail = data => {
  return http({
    method: "get",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/nodes/${data.node}`
  });
};

export const clusterPods = (data, params) => {
  return http({
    method: "get",
    params,
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/pods`
  });
};

export const nodePods = (data, params) => {
  return http({
    method: "get",
    params,
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/nodes/${data.node}/pods`
  });
};

export const nodeEvent = data => {
  return http({
    method: "get",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/nodes/${data.node}/events`
  });
};

export const nodeSchedulable = (data, params) => {
  return http({
    method: "patch",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/nodes/${data.name}`,
    data: {
      "spec": {
        "unschedulable": params.action === "unschedulable"
      }
    }
  });
};

export const nodeUpdate = (payload, data) => {
  return http({
    method: "put",
    data,
    url: `/k8s.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/nodes/${payload.node}`
  });
};

export const createClusterConf = (provider, data) => {
  return http({
    method: "post",
    data,
    url: `/manage.kubestar.io/providers/${provider}/providerconfigs`
  });
};

export const updateClusterConf = (provider, data) => {
  return http({
    method: "put",
    data,
    url: `/manage.kubestar.io/providers/${provider}/providerconfigs`
  });
};

export const getClusterConf = provider => {
  return http({
    method: "get",
    url: `/manage.kubestar.io/providers/${provider}/providerconfigs`
  });
};

export const drainNode = payload => {
  return http({
    method: "post",
    url: `/k8s.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/nodes/${payload.node}/drain`
  })
}

export const clusterComponents = (data, params) => {
  return http({
    method: "get",
    url: `/manage.kubestar.io/organizations/${data.organization}/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/components`,
    params
  });
};

export const clusterComponentDetail = (data) => {
  return http({
    method: "get",
    url: `/manage.kubestar.io/organizations/${data.organization}/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/components/${data.component}`
  });
}

export const clusterEventList = (payload, params) => {
  return http({
    method: "get",
    url: `/k8s.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/events`,
    params
  })
};

export const clusterEventLabelList = (payload, params) => {
  return http({
    method: "get",
    url: `/k8s.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/events/labels`,
    params
  })
}