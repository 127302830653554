<template>
  <el-dialog :title="$t('handle.add')" :visible="dialogVisible" @close="close" top="30px" append-to-body :close-on-click-modal="false">
    <edit-labels v-if="dialogVisible" :data="labels" ref="editLabelForm" :selectable="true" :edit-type="'labelEdit'" />

    <span slot="footer">
      <el-button type="primary" @click="submit" size="small" :loading="loading">
        {{ $t("handle.submit") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { isEmpty, transform, isObject } from "lodash";

export default {
  name: "Labels",

  data() {
    return {
      loading: false
    };
  },
  props: {
    dialogVisible: {
      type: Boolean
    },
    labels: {
      type: Object
    }
  },
  methods: {
    isEmpty,
    isObject,

    isJSON(str) {
      if (typeof str == "string") {
        try {
          let obj = JSON.parse(str);
          if (typeof obj == "object" && obj) {
            return true;
          } else {
            return false;
          }
        } catch {
          return false;
        }
      }

      return false;
    },

    jsonEditorInit: function() {
      require("brace/ext/searchbox");
      require("brace/mode/json");
      require("brace/theme/cobalt");
    },

    submit() {
      this.$refs["editLabelForm"].$refs["form"].validate(valid => {
        if (valid) {
          let annotations = transform(
            this.$refs["editLabelForm"].form.labels,
            function(result, item) {
              result[item.key] = item.value;
            },
            {}
          );
          this.$refs["editLabelForm"].form.labels = [];
          this.$emit("addTags", annotations);
        }
      });
    },
    close() {
      this.$emit("close");
    }
  },

  computed: {
    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    name() {
      return this.$route.params.name;
    },

    namespace() {
      return this.$route.query.namespace;
    },

    type() {
      return this.$route.params.type;
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
.labels-container {
  position: relative;

  .el-icon-edit-outline {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -20px;
    @include title(20px);
  }
}
</style>
