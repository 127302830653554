<template>
  <div>
    <editor v-model="yamlText" @init="editorInit" lang="yaml" theme="cobalt" width="100%" height="100vh"></editor>
  </div>
</template>

<script>
const YAML = require("json2yaml");

export default {
  name: "YamlContainer",
  props: {
    text: {
      //type: String
    },
    transfer: {
      type: Boolean,
      default: true
    }
  },

  components: {
    editor: require("vue2-ace-editor")
  },

  methods: {
    editorInit(editor) {
      editor.setReadOnly(true);
      require("brace/ext/searchbox");
      require("brace/mode/yaml");
      require("brace/theme/cobalt");
    }
  },

  computed: {
    yamlText: {
      get() {
        return this.transfer ? YAML.stringify(this.text).replace(/^[\s]{2}/gm, "") : this.text;
        // return this.transfer ? YAML.stringify(this.text) : this.text;
      },

      set() {}
    }
  }
};
</script>

<style></style>
