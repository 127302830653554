export default {
  ConfigMap: "コンフィグ設定",
  configMapName: "コンフィグ名称",
  configMapRootPath: "コンフィグ絶対パス",
  mountPath: "グマントパス",
  configMapItem: "項目",
  mountContainer: "コントコンテナ：",
  mountFile: "マントファイル",
  fileContent: "ファイルの内容",
  overlay: "上書き",
  mode: "読取/書込権限"
};
